<template>
  <v-app class="app">
    <v-content>
      <div class="right">
        <v-container>
          <v-layout>
            <v-flex>
              <router-view></router-view>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </v-content>
    <card-preferences-sidebar />
    <apply-to-all-cards-dialog />
    <remove-card-dialog />
    <too-many-cards-dialog
      @onCancel="onTooManyCardsDialogCancel"
      @onContinue="onTooManyCardsDialogOnContinue"
    />
    <update-cards-dialog />
    <toast-handler />
    <discard-active-card-changes-dialog />
  </v-app>
</template>

<script>
import CardPreferencesSidebar from '@/components/organisms/CardPreferencesSidebar.vue';
import ApplyToAllCardsDialog from '@/components/organisms/ApplyToAllCardsDialog.vue';
import RemoveCardDialog from '@/components/organisms/RemoveCardDialog.vue';
import TooManyCardsDialog from '@/components/organisms/TooManyCardsDialog.vue';
import UpdateCardsDialog from '@/components/organisms/UpdateCardsDialog.vue';
import ToastHandler from '@/components/organisms/ToastHandler.vue';
import DiscardActiveCardChangesDialog from '@/components/organisms/DiscardActiveCardChangesDialog.vue';

export default {
  components: {
    CardPreferencesSidebar,
    ApplyToAllCardsDialog,
    RemoveCardDialog,
    TooManyCardsDialog,
    UpdateCardsDialog,
    ToastHandler,
    DiscardActiveCardChangesDialog
  },
  methods: {
    onTooManyCardsDialogCancel() {
      this.$store.dispatch('ui/toggleTooManyCardsDialogOpen', false);
    },
    onTooManyCardsDialogOnContinue() {
      this.$store.dispatch('cardPreferences/addCard', false);
      this.$store.dispatch('ui/toggleTooManyCardsDialogOpen', false);
    },
    onUnload(event) {
      event.preventDefault();
      event.returnValue = '';
      // this message is only display in IE, other browsers have a generic message
      return 'Are you sure you want to leave without confirming card order?';
    }
  },
  mounted() {
    window.addEventListener('beforeunload', this.onUnload);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.onUnload);
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.right {
  width: 100%;
}
</style>
