<template>
  <div
    :class="[
      'sidebar-card',
      { 'sidebar-card--active': active },
      { 'sidebar-card--disabled': disabled }
    ]"
    @click="onClick"
  >
    <z-card
      :label="cardLabel"
      :has-error="hasError"
      :scale-label="scaleCardLabel"
      :text="$vuetify.breakpoint.xsOnly ? cardSummary : undefined"
      class="sidebar-card__card"
      orange-shadow
      removable
      @remove="onRemove"
    />
    <div v-if="$vuetify.breakpoint.smAndUp" class="sidebar-card__summary">
      <div class="card-for">This {{ brand }} fuel card is for</div>
      <typography type="h5" class="sidebar-card__summary-text" light>{{
        cardSummary
      }}</typography>
      <error-message v-if="hasError" class="error">Incomplete</error-message>
    </div>
  </div>
</template>

<script>
import Typography from '@/components/atoms/Typography.vue';
import ZCard from '@/components/molecules/ZCard.vue';
import ErrorMessage from '@/components/molecules/ErrorMessage.vue';
import { BRAND } from '@/constants/form';

export default {
  components: {
    Typography,
    ZCard,
    ErrorMessage
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    cardLabel: {
      type: String,
      default: ''
    },
    cardSummary: {
      type: String,
      default: ''
    },
    scaleCardLabel: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    brand: () => BRAND
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
    onRemove() {
      if (!this.disabled) {
        this.$emit('remove');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.sidebar-card {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  @include sm {
    padding: rem(5px 20px 5px 15px);
    margin: rem(-8px 0);
  }

  &--active {
    @include sm {
      background-color: #f26522;
      border-top-left-radius: rem(26px);
      border-bottom-left-radius: rem(26px);
    }
  }

  &--disabled {
    opacity: 0.3;
  }

  &__card {
    @include sm {
      transform: scale(0.9);
    }

    .sidebar-card--active & {
      @include sm {
        transform: scale(1);
      }
    }
  }

  &__summary {
    position: relative;
    padding: rem(30px 0 0);
    margin-left: rem(20px);
    margin-bottom: rem(30px);
    width: rem(150px);

    .error {
      position: absolute;
      left: 0;
      top: 100%;
    }
  }

  &__summary-text {
    min-height: rem(64px);
    max-width: 100%;
    overflow: hidden;
  }
}

.card-for {
  opacity: 0.6;
  font-size: rem(15px);
  letter-spacing: 0.5px;
  line-height: rem(15px);
  margin-bottom: rem(10px);
}
</style>
