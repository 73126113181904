import { BASE_URL } from '@/helpers/config';

/**
 * Takes a relative URL and converts it to an absolute URL.
 * @param {string} origin The origin of the current URL.
 * @param {string} relativeUrl The relative URL.
 * @param {string} basePath The base path. Default is pulled from the config helper.
 * @returns {string} The absolute URL.
 */
export function toAbsoluteUrl(origin, relativeUrl, basePath = BASE_URL) {
  var result = `${origin}${basePath}${relativeUrl}`;

  // Use regex to swap any double slashes for singles.
  return result.replace(/(:\/\/)|(\/)+/g, '$1$2');
}

/**
 * Takes a relative URL and prefixes the base URL.
 * @param {string} relativeUrl The relative URL.
 * @returns {string} The URL including the base URL.
 */
export function toUrl(relativeUrl) {
  var result = `${BASE_URL}${relativeUrl}`;

  // Use regex to swap any double slashes for singles.
  return result.replace(/(:\/\/)|(\/)+/g, '$1$2');
}
