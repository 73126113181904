/* eslint-disable no-console */
import { INSTRUMENTATION_KEY, IS_DEVELOPMENT } from '@/helpers/config';
import { AppInsights } from 'applicationinsights-js';

let logger;

if (IS_DEVELOPMENT) {
  logger = {
    error(ex) {
      console.error(ex);
    }
  };
} else if (INSTRUMENTATION_KEY) {
  AppInsights.downloadAndSetup({ instrumentationKey: INSTRUMENTATION_KEY });
  logger = {
    appInsights: AppInsights,
    error(ex) {
      AppInsights.trackException(ex);
    }
  };
} else {
  console.warn('Instrumentation key not found. Logging has been disabled.');
  logger = {
    // eslint-disable-next-line no-unused-vars
    error(ex) {
      // Swallow errors
    }
  };
}

export default logger;
