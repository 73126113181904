import VueGtm from 'vue-gtm';
import { GTM_ID, IS_DEVELOPMENT } from '@/helpers/config';

// See https://github.com/mib200/vue-gtm
const plugin = {
  install(vue, options) {
    if (GTM_ID) {
      vue.use(VueGtm, {
        id: GTM_ID,
        vueRouter: options.router,
        debug: IS_DEVELOPMENT
      });
    }
  }
};

export default plugin;
