<template>
  <nav :class="['navigation', `navigation--${direction}`]">
    <text-button
      :active="currentSection === yourBusinessSectionId"
      class="nav-btn"
      @click="onNavItemClick(yourBusinessSectionId)"
      >Your business</text-button
    >
    <text-button
      :active="currentSection === yourOfferSectionId"
      :disabled="!businessSectionComplete"
      class="nav-btn"
      @click="onNavItemClick(yourOfferSectionId)"
      >Your offer</text-button
    >
    <text-button
      :active="currentSection === creditCheckSectionId"
      :disabled="!offerSectionComplete"
      class="nav-btn"
      @click="onNavItemClick(creditCheckSectionId)"
      >Credit check</text-button
    >
    <text-button
      :active="currentSection === finalDetailsSectionId"
      :disabled="!creditSectionComplete"
      class="nav-btn"
      @click="onNavItemClick(finalDetailsSectionId)"
      >Final details</text-button
    >
    <text-button :active="false" :disabled="true" class="nav-btn"
      >Order cards</text-button
    >
  </nav>
</template>

<script>
import TextButton from '@/components/atoms/TextButton.vue';
import {
  SECTION_YOUR_BUSINESS,
  SECTION_YOUR_OFFER,
  SECTION_CREDIT_CHECK,
  SECTION_FINAL_DETAILS
} from '@/constants/html-ids';
import { FIXED_HEADER_OFFSET } from '@/constants/ui';
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    TextButton
  },
  props: {
    direction: {
      type: String,
      default: 'row'
    }
  },
  computed: {
    ...mapState({
      businessSectionComplete: state => state.business.sectionComplete,
      offerSectionComplete: state => state.offer.sectionComplete,
      creditSectionComplete: state => state.credit.sectionComplete
    }),
    ...mapGetters({
      currentSection: 'navigation/currentSection'
    }),
    yourBusinessSectionId: () => SECTION_YOUR_BUSINESS,
    yourOfferSectionId: () => SECTION_YOUR_OFFER,
    creditCheckSectionId: () => SECTION_CREDIT_CHECK,
    finalDetailsSectionId: () => SECTION_FINAL_DETAILS
  },
  methods: {
    onNavItemClick(sectionId) {
      this.$vuetify.goTo(`#${sectionId}`, {
        offset: FIXED_HEADER_OFFSET
      });

      this.$store.dispatch('ui/toggleMobileMenuOpen', false);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.navigation {
  display: flex;
  align-items: center;

  &--row {
    height: 100%;
    flex-direction: row;

    .nav-btn {
      margin-right: rem(40px);
    }
  }

  &--column {
    flex-direction: column;

    .nav-btn {
      margin: rem(15px 0);
    }
  }
}
</style>
