<template>
  <v-app>
    <v-content>
      <router-view></router-view>
    </v-content>
    <toast-handler />
  </v-app>
</template>

<script>
import ToastHandler from '@/components/organisms/ToastHandler.vue';

export default {
  components: {
    ToastHandler
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';
</style>
