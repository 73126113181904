<template>
  <v-expansion-panel-content
    :disabled="disabled"
    :expand-icon="expandIcon"
    class="expansion-panel-content"
  >
    <slot slot="header" name="header" />
    <slot />
  </v-expansion-panel-content>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    expandIcon: {
      type: String,
      default: 'add'
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.expansion-panel-content {
  background-color: transparent !important;
  color: inherit !important;
  border-top: none !important;

  &.v-expansion-panel__container--disabled {
    /deep/ .v-expansion-panel__header {
      opacity: 0.3;
      border-bottom: 1px solid $white;
    }
  }

  /deep/ .v-expansion-panel {
    &__header {
      padding: 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      font-weight: $weight-black;
      font-size: rem(20px);
    }

    &__body {
      padding: rem(10px 0);
      transition: 0.2s cubic-bezier(0.25, 0.8, 0.5, 1);
    }

    &__header__icon {
      display: block !important;

      i {
        color: inherit !important;
        // Stop 180 deg rotate animation
        transform: none !important;
      }
    }
  }
}
</style>
