<template>
  <modal v-model="tooManyCardsDialogOpen">
    <header class="dialog-header">
      <v-layout justify-center>
        <v-flex sm10>
          <typography type="h3">
            Looks like you've got a few {{ brand }} fuel cards up your sleeve.
          </typography>
        </v-flex>
      </v-layout>
      <v-layout justify-center>
        <v-flex sm8>
          <typography light>
            We can help tailor your cards if you need a hand — just send your
            card details to
            <a :href="`mailto:${zEmail}`" name="tooManyCardsDialogEmailLink">
              {{ zEmail }}
            </a>
            and we’ll sort you out.
          </typography>
        </v-flex>
      </v-layout>
    </header>

    <round-button
      slot="left-button"
      outline
      @click="onCancel"
      name="tooManyCardsDialogYesButton"
      >{{ cancelLabel }}</round-button
    >
    <round-button
      slot="right-button"
      @click="onContinue"
      name="tooManyCardsDialogNoButton"
      >No thanks, I'll do it myself</round-button
    >
  </modal>
</template>

<script>
import Modal from '@/components/molecules/Modal.vue';
import Typography from '@/components/atoms/Typography.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';
import { Z_EMAIL } from '@/constants/routes';
import { BRAND } from '@/constants/form';
import { mapState, mapGetters } from 'vuex';

export default {
  props: {
    cancelLabel: {
      type: String,
      default: `Back to ${BRAND} Cards`
    }
  },
  components: {
    Modal,
    Typography,
    RoundButton
  },
  computed: {
    ...mapState({
      applicationType: state => state.saver.applicationType
    }),
    ...mapGetters({
      brand: 'saver/brand'
    }),
    tooManyCardsDialogOpen: {
      get() {
        return this.$store.state.ui.tooManyCardsDialogOpen;
      },
      set(value) {
        this.$store.dispatch('ui/toggleTooManyCardsDialogOpen', value);
      }
    },
    zEmail: () => Z_EMAIL
  },
  methods: {
    onCancel() {
      this.$emit('onCancel');
    },
    onContinue() {
      this.$emit('onContinue');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.dialog-header {
  margin-bottom: rem(30px);
}
</style>
