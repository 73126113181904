<template>
  <modal v-model="shareDialog">
    <div v-if="!progress.success">
      <header class="dialog-header">
        <typography type="h3">Save and share</typography>
        <typography light>
          You can use the following secure link to send to a friend or resume
          your form from
          <strong>any device</strong>. This link will expire in 3 months.
        </typography>
      </header>
      <v-layout justify-center>
        <v-flex sm10>
          <copy-text
            :text="
              generateSaveLinkInProgress ? 'Generating save link...' : saveLink
            "
            class="copy-text"
          />
          <div class="emails">
            <text-input
              v-for="(email, i) in emails"
              :key="i"
              :value="email"
              :label="i === 0 ? 'Email link to' : undefined"
              :placeholder="i !== 0 ? 'Email address' : undefined"
              type="email"
              class="email-input"
              @input="val => onChangeEmail(val, i)"
              :rules="[
                () => $v.emails.$each[i].email || 'This email is invalid'
              ]"
            />
            <typography
              v-if="emails.length < 5"
              type="small-p"
              class="add-email"
              align="left"
              @click="onAddEmail"
              >+ Add another email address</typography
            >
          </div>
        </v-flex>
      </v-layout>
    </div>
    <div v-else>
      <typography type="h3">Nice, link was sent!</typography>
      <typography no-margin light
        >A link to your saved form was sent to:</typography
      >
      <v-layout mb-3>
        <v-flex>
          <typography
            v-for="(email, i) in emails"
            v-if="email"
            :key="i"
            light
            no-margin
            >{{ email }}</typography
          >
        </v-flex>
      </v-layout>
      <typography type="small-p">
        Didn't get the email?
        <a href="#" @click.stop="onResendClick">Resend link.</a>
      </typography>
    </div>

    <round-button slot="left-button" outline @click="onResumeForm"
      >Resume form</round-button
    >

    <round-button
      v-if="!progress.success"
      slot="right-button"
      :loading="progress.inProgress"
      :disabled="$v.emails.$invalid"
      @click="onSendLink"
      >Send link</round-button
    >
    <round-button v-else slot="right-button" @click="onBackToHome"
      >Back to Z home</round-button
    >
  </modal>
</template>

<script>
import Modal from '@/components/molecules/Modal.vue';
import Typography from '@/components/atoms/Typography.vue';
import CopyText from '@/components/molecules/CopyText.vue';
import TextInput from '@/components/atoms/TextInput.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';
import { mapState } from 'vuex';
import { HOME } from '@/constants/routes';
import { email } from '@/validators';

export default {
  components: {
    Modal,
    Typography,
    CopyText,
    TextInput,
    RoundButton
  },
  computed: {
    shareDialog: {
      get() {
        return this.$store.state.ui.shareDialogOpen;
      },
      set(value) {
        this.$store.dispatch('ui/toggleShareDialog', value);
      }
    },
    ...mapState({
      saveLink: state => state.saver.saveLink,
      emails: state => state.saver.emails,
      progress: state => state.progress.emailSaveLinks,
      generateSaveLinkInProgress: state =>
        state.progress.generateSaveLink.inProgress
    })
  },
  methods: {
    onChangeEmail(value, index) {
      this.$store.dispatch('saver/changeEmails', { index, value });
    },
    onAddEmail() {
      this.$store.dispatch('saver/addEmail');
    },
    onResumeForm() {
      this.$store.dispatch('ui/toggleShareDialog', false);
    },
    onSendLink() {
      this.$store.dispatch('saver/emailSaveLinks');
    },
    onResendClick() {
      // Reset
      this.$store.commit('progress/changeProgress', {
        key: 'emailSaveLinks',
        type: 'initial'
      });
    },
    onBackToHome() {
      window.location.href = HOME;
    }
  },
  validations: {
    emails: {
      required: value => value && value.some(v => v && v !== ''),
      $each: {
        email
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.dialog-header {
  margin-bottom: rem(30px);
}

.copy-text {
  margin-bottom: rem(40px);

  @include md {
    margin-bottom: rem(60px);
  }
}

.emails {
  @include md {
    margin-bottom: rem(30px);
  }

  .email-input {
    margin-bottom: 0;
  }
}

.add-email {
  cursor: pointer;
  color: $mid-orange;
}
</style>
