export const ENVIRONMENT = process.env.NODE_ENV;
export const API_BASE_URL = process.env.VUE_APP_API_ROOT;
export const GTM_ID = process.env.VUE_APP_GTM_ID;
export const BASE_URL = process.env.BASE_URL;
export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';
export const IS_PRERENDERING = window.__PRERENDER_INJECTED;
export const TIMEOUT_MINS = process.env.VUE_APP_TIMEOUT_MINS;
export const INSTRUMENTATION_KEY =
  process.env.VUE_APP_INSTRUMENTATION_KEY ||
  (window.env && window.env.INSTRUMENTATION_KEY);
