import Vue from 'vue';
import Router from 'vue-router';
import store from './store';
import {
  LANDING,
  REQUIREMENTS,
  FORM,
  SPECIAL_RATES,
  CREDIT_CHECK_FAILED,
  NEXT_STEPS,
  CARD_PREFERENCES,
  CARD_PREFERENCES_COMPLETED,
  CALLBACK_REQUESTED,
  TIMEOUT
} from '@/constants/routes';

Vue.use(Router);

const scrollBehavior = function(to) {
  return new Promise(resolve => {
    if (to.hash) {
      setTimeout(() => {
        resolve({ selector: to.hash });
      }, 500);
    } else {
      resolve({ x: 0, y: 0 });
    }
  });
};

/*
 * Route meta data properties:
 * - layout: the master page for the rendered view. see the layouts folder.
 * - requireApplicationId: the route is only accessible if the application has been started (first form completed).
 * - completed: the route is an end of the form. if the user tries to navigate back they'll be sent to the start of the form.
 */
const routes = [
  {
      path: '/camp/*',
      redirect: to => {
        console.log(to);
        window.location.href  = `${process.env.VUE_APP_CAMPSITE_URL}/${to.params.pathMatch}`;
      }
  }, 
  {
    path: '/aviation*',
    redirect: to => {
      console.log(to);
      window.location.href  = `${process.env.VUE_APP_AVIATION_REDIRECT}`;
    }
},
  {
    name: 'requirements',
    path: REQUIREMENTS,
    meta: {
      requireApplicationId: true
    },
    component: () =>
      import(/* webpackChunkName: "pages_requirements" */ './views/requirements/index.vue').then(
        m => m.default || m
      )
  },
  {
    name: 'card-preferences',
    path: CARD_PREFERENCES,
    meta: {
      layout: 'card-preferences',
      requireApplicationId: true
    },
    component: () =>
      import(/* webpackChunkName: "pages_card_preferences" */ './views/card-preferences/index.vue').then(
        m => m.default || m
      )
  },
  {
    name: 'card-preferences-completed',
    path: CARD_PREFERENCES_COMPLETED,
    meta: {
      requireApplicationId: true,
      completed: true
    },
    component: () =>
      import(/* webpackChunkName: "pages_card_preferences_completed" */ './views/completed.vue').then(
        m => m.default || m
      )
  },
  {
    name: 'form-special-rates',
    path: SPECIAL_RATES,
    meta: {
      requireApplicationId: true
    },
    component: () =>
      import(/* webpackChunkName: "pages_form_special_rates" */ './views/form/special-rates/index.vue').then(
        m => m.default || m
      )
  },
  {
    name: 'form-next-steps',
    path: NEXT_STEPS,
    meta: {
      requireApplicationId: true
    },
    component: () =>
      import(/* webpackChunkName: "pages_form_next_steps" */ './views/form/next-steps/index.vue').then(
        m => m.default || m
      )
  },
  {
    name: 'form-credit-check-failed',
    path: CREDIT_CHECK_FAILED,
    meta: {
      requireApplicationId: true,
      completed: true
    },
    component: () =>
      import(/* webpackChunkName: "pages_form_credit_check_failed" */ './views/form/credit-check-failed/index.vue').then(
        m => m.default || m
      )
  },
  {
    name: 'form-callback-requested',
    path: CALLBACK_REQUESTED,
    meta: {
      requireApplicationId: true,
      completed: true
    },
    component: () =>
      import(/* webpackChunkName: "pages_form_callback_requested" */ './views/form/callback-requested/index.vue').then(
        m => m.default || m
      )
  },
  {
    name: 'form',
    path: FORM,
    meta: {
      layout: 'form',
      requireApplicationId: true
    },
    props: true,
    component: () =>
      import(/* webpackChunkName: "pages_form" */ './views/form/index.vue').then(
        m => m.default || m
      ),
    beforeEnter: (to, from, next) => {
      // Prevent users from navigation back to the form from the next steps page.
      if (from.path == NEXT_STEPS) {
        next(false);
        return;
      }
      next();
    }
  },
  {
    name: 'form-from-draft',
    path: `${FORM}/:applicationId?/draft/:draftId?`,
    meta: {
      layout: 'form'
    },
    props: true,
    component: () =>
      import(/* webpackChunkName: "pages_form" */ './views/form/index.vue').then(
        m => m.default || m
      ),
    // Check the application id and draft id have been supplied
    beforeEnter: function(to, from, next) {
      if (
        store.state.saver.applicationId != '' ||
        (to.params.applicationId && to.params.draftId)
      ) {
        next();
      } else {
        next(LANDING);
      }
    }
  },
  {
    name: 'landing',
    path: LANDING,
    component: () =>
      import(/* webpackChunkName: "pages_index" */ './views/index.vue').then(
        m => m.default || m
      )
  },
  {
    name: 'timeout',
    path: TIMEOUT,
    beforeEnter: (to, from, next) => {
      // show modal message
      store.dispatch(
        'ui/addAlert',
        {
          id: 'Session-Expired',
          text:
            'Sorry, the information that you provided has expired. Please restart the form.'
        },
        { root: true }
      );

      next(LANDING);
    }
  },
  {
    name: 'not-found',
    path: '*',
    meta: {
      title: 'Page Not Found'
    },
    component: () =>
      import(/* webpackChunkName: "pages_notfound" */ './views/not-found.vue').then(
        m => m.default || m
      )
  }
];

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior,
  routes
});

router.beforeEach((to, from, next) => {
  // Prevent users skipping ahead in the form without completing the first page.
  if (to.meta.requireApplicationId) {
    if (store.state.saver.applicationId === '') {
      next(LANDING);
      return;
    }
  }

  // Prevent users from navigating back to see details about the completed form.
  if (from.meta.completed) {
    // Use window.location.reload().location.href to ensure any state data
    // is cleared. This should result in the user being sent back to the beginning of the form.
    window.location.reload();
    return;
  }

  // Start the loading bar progress.
  store.dispatch('ui/changeIsLoading', true);

  // The loading bar doesn't work properly without this :/
  setTimeout(() => {
    next();
  }, 1);
});

router.afterEach(() => {
  store.dispatch('ui/changeIsLoading', false);
});

export default router;
