<template>
  <nav class="carousel-navigation">
    <carousel-arrow
      v-if="$vuetify.breakpoint.smAndUp"
      :disabled="previousArrowDisabled"
      @click="onPrevArrowClick"
    />
    <div class="dots">
      <carousel-dot
        v-for="item in items"
        :key="item.value"
        :active="item.value === value"
        :disabled="item.disabled"
        @click="onClick(item)"
      />
    </div>
    <carousel-arrow
      v-if="$vuetify.breakpoint.smAndUp"
      :disabled="nextArrowDisabled"
      reverse
      @click="onNextArrowClick"
    />
  </nav>
</template>

<script>
import CarouselArrow from '@/components/atoms/CarouselArrow.vue';
import CarouselDot from '@/components/atoms/CarouselDot.vue';

export default {
  components: {
    CarouselArrow,
    CarouselDot
  },
  props: {
    value: {
      type: null,
      default: null
    },
    items: {
      type: Array,
      default: () => []
    },
    previousArrowDisabled: {
      type: Boolean,
      default: false
    },
    nextArrowDisabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick({ value }) {
      this.$emit('click', value);
    },
    onPrevArrowClick() {
      this.$emit('prev-click');
    },
    onNextArrowClick() {
      this.$emit('next-click');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.dots {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
