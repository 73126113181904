import logger from '@/helpers/logging';

export const initialProgress = {
  inProgress: false,
  hasLoaded: false,
  hasError: false,
  success: false,
  errorMessages: []
};

export const errorProgress = {
  inProgress: false,
  hasLoaded: true,
  hasError: true,
  success: false,
  errorMessages: []
};

export const requestProgress = {
  inProgress: true,
  hasLoaded: false,
  hasError: false,
  success: false,
  errorMessages: []
};

export const successProgress = {
  inProgress: false,
  hasLoaded: true,
  hasError: false,
  success: true,
  errorMessages: []
};

export const state = () => ({
  generateSaveLink: {
    ...initialProgress
  },
  saveForm: {
    ...initialProgress
  },
  loadSavedForm: {
    ...initialProgress
  },
  createApplication: {
    ...initialProgress
  },
  emailSaveLinks: {
    ...initialProgress
  },
  submitPersonalGuarantee: {
    ...initialProgress
  },
  businessNameLookup: {
    ...initialProgress
  },
  residentialAddressLookup: {
    ...initialProgress
  },
  deliveryAddressLookup: {
    ...initialProgress
  },
  physicalAddressLookup: {
    ...initialProgress
  },
  submitApplication: {
    ...initialProgress
  },
  submitCardChanges: {
    ...initialProgress
  },
  submitRequestCallback: {
    ...initialProgress
  }
});

export const mutations = {
  changeProgress(state, { key, type }) {
    if (!state.hasOwnProperty(key)) {
      logger.error(`Key '${key}' does not exist in the progress module`);
      return;
    }

    switch (type) {
      case 'request':
        state[key] = {
          ...requestProgress
        };
        break;
      case 'success':
        state[key] = {
          ...successProgress
        };
        break;
      case 'error':
        state[key] = {
          ...errorProgress
        };
        break;
      case 'initial':
        state[key] = {
          ...initialProgress
        };
        break;
      default:
        break;
    }
  }
};

export const actions = {};

export const getters = {};
