import logger from '@/helpers/logging';

export const state = () => ({
  toasts: [],
  isLoading: false,
  showHelp: false,
  // Main form
  drawerOpen: false,
  openDrawerPanelIndex: 0,
  shareDialogOpen: false,
  personalGuaranteeDialogOpen: false,
  personalCardDialogOpen: false,
  // Card preferences
  cardPreferencesSidebarOpen: true,
  updateCardsDialogOpen: false,
  tooManyCardsDialogOpen: false,
  removeCardDialogOpen: false,
  applyToAllCardsDialogOpen: false,
  discardActiveCardChangesDialogOpen: false,
  mobileMenuOpen: false
});

export const getters = {
  isLoading: state => state.isLoading
};

export const mutations = {
  addToast(state, { id, text, type, canDismiss }) {
    state.toasts = [...state.toasts, { id, text, type, canDismiss }];
  },
  removeToastByIndex(state, indexToRemove) {
    state.toasts = state.toasts.filter((toast, index) => {
      return indexToRemove !== index;
    });
  },
  removeToastById(state, idToRemove) {
    state.toasts = state.toasts.filter(toast => {
      return idToRemove !== toast.id;
    });
  },
  removeAllToasts(state) {
    state.toasts = [];
  },
  toggleDrawer(state, isOpen) {
    state.drawerOpen = isOpen;
  },
  changeIsLoading(state, value) {
    state.isLoading = value;
  },
  changeDrawerPanelIndex(state, index) {
    state.openDrawerPanelIndex = index;
  },
  toggleShareDialog(state, isOpen) {
    state.shareDialogOpen = isOpen;
  },
  togglePersonalGuaranteeDialog(state, isOpen) {
    state.personalGuaranteeDialogOpen = isOpen;
  },
  toggleCardPreferencesSidebarOpen(state, isOpen) {
    state.cardPreferencesSidebarOpen = isOpen;
  },
  toggleUpdateCardsDialogOpen(state, isOpen) {
    state.updateCardsDialogOpen = isOpen;
  },
  toggleTooManyCardsDialogOpen(state, isOpen) {
    state.tooManyCardsDialogOpen = isOpen;
  },
  toggleRemoveCardDialogOpen(state, isOpen) {
    state.removeCardDialogOpen = isOpen;
  },
  toggleApplyToAllCardsDialogOpen(state, isOpen) {
    state.applyToAllCardsDialogOpen = isOpen;
  },
  togglePersonalCardDialogOpen(state, isOpen) {
    state.personalCardDialogOpen = isOpen;
  },
  toggleMobileMenuOpen(state, isOpen) {
    state.mobileMenuOpen = isOpen;
  },
  toggleDiscardActiveCardChangesDialogOpen(state, isOpen) {
    state.discardActiveCardChangesDialogOpen = isOpen;
  },
  toggleHelp(state, show) {
    state.showHelp = show;
  },
};

export const actions = {
  addErrorToast({ commit }, { id, text, error }) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.exception
    ) {
      const exception = error.response.data.exception;

      // If it's an unhandled exception
      if (error.response.status === 500) {
        logger.error(exception);
      }
      // If it's a validation error
      else if (error.response.status === 400) {
        // Check if the error message is an array or single string
        if (Array.isArray(exception)) {
          for (let i = 0; i < exception.length; i++) {
            // Put a slight delay between each toast
            setTimeout(() => {
              commit('addToast', {
                id: `${id}${i}`,
                text: exception[i].errorMessage,
                type: 'error'
              });
            }, i * 500);
          }
          return;
        }

        // If it's not an array, then it should be some text we can display.
        text = exception;
      }
    }

    commit('addToast', { id, text, type: 'error' });
  },
  /**  @description Similar to a toast but must be dismissed by the user. */
  addAlert({ commit }, { id, text }) {
    commit('addToast', { id, text, type: 'error', canDismiss: true });
  },
  addToast({ commit }, { id, text }) {
    commit('addToast', { id, text, canDismiss: false });
  },
  removeToastByIndex({ commit }, index) {
    commit('removeToastByIndex', index);
  },
  removeToastById({ commit }, id) {
    commit('removeToastById', id);
  },
  removeAllToasts({ commit }) {
    commit('removeAllToasts');
  },
  toggleDrawer({ commit }, isOpen) {
    commit('toggleDrawer', isOpen);
  },
  changeIsLoading({ commit }, value) {
    commit('changeIsLoading', value);
  },
  changeDrawerPanelIndex({ commit }, index) {
    commit('changeDrawerPanelIndex', index);
  },
  toggleShareDialog({ commit, dispatch }, isOpen) {
    if (isOpen) {
      // Prepopulate email
      dispatch('saver/prepopulateEmail', null, { root: true });
      // Reset the email links state when opening
      commit(
        'progress/changeProgress',
        {
          key: 'emailSaveLinks',
          type: 'initial'
        },
        {
          root: true
        }
      );
    }

    commit('toggleShareDialog', isOpen);
  },
  togglePersonalGuaranteeDialog({ commit, dispatch }, isOpen) {
    if (isOpen) {
      // Prepopulate personal guarantee email
      dispatch('credit/prepopulatePersonalGuaranteeEmail', null, {
        root: true
      });
    }

    commit('togglePersonalGuaranteeDialog', isOpen);
  },
  toggleCardPreferencesSidebarOpen({ commit }, isOpen) {
    commit('toggleCardPreferencesSidebarOpen', isOpen);
  },
  toggleUpdateCardsDialogOpen({ commit }, isOpen) {
    commit('toggleUpdateCardsDialogOpen', isOpen);
  },
  toggleTooManyCardsDialogOpen({ commit }, isOpen) {
    commit('toggleTooManyCardsDialogOpen', isOpen);
  },
  toggleRemoveCardDialogOpen({ commit }, isOpen) {
    commit('toggleRemoveCardDialogOpen', isOpen);
  },
  toggleApplyToAllCardsDialogOpen({ commit }, isOpen) {
    commit('toggleApplyToAllCardsDialogOpen', isOpen);
  },

  togglePersonalCardDialogOpen({ commit }, isOpen) {
    commit('togglePersonalCardDialogOpen', isOpen);
  },
  toggleMobileMenuOpen({ commit }, isOpen) {
    commit('toggleMobileMenuOpen', isOpen);
  },
  toggleDiscardActiveCardChangesDialogOpen({ commit }, isOpen) {
    commit('toggleDiscardActiveCardChangesDialogOpen', isOpen);
  },
  toggleHelp({ commit }, show) {
    commit('toggleHelp', show);
  },
};
