<template>
  <v-progress-circular
    :size="size"
    :width="width"
    class="circular-progress"
    indeterminate
  />
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 32
    },
    width: {
      type: Number,
      default: 4
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.circular-progress {
  /deep/ .v-progress-circular__overlay {
    stroke: $mid-orange;
  }
}
</style>
