<template>
  <div class="icon-button" @click.stop="onClick">
    <slot />
  </div>
</template>

<script>
export default {
  methods: {
    onClick() {
      this.$emit('click');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.icon-button {
  display: inline-flex;
  cursor: pointer;

  svg {
    transition: all 0.3s ease;
  }

  &:hover {
    svg {
      transform: scale(1.03);
    }
  }
}
</style>
