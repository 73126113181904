<template>
  <img src="@/assets/Z-Logo.png" alt="Z Energy Logo" />
</template>

<script>
import LogoIcon from '@/assets/icons/Z-logo.svg';

export default {
  components: {
    LogoIcon
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';
</style>
