<template>
  <modal v-model="applyToAllCardsDialogOpen">
    <header class="dialog-header">
      <v-layout justify-center>
        <v-flex sm9>
          <typography type="h3"
            >Are you sure you want to apply to all cards?</typography
          >
        </v-flex>
      </v-layout>
      <v-layout justify-center>
        <v-flex sm7>
          <typography light
            >This will overwrite any existing values you have set for all your
            other {{ brand }} fuel cards.</typography
          >
        </v-flex>
      </v-layout>
    </header>

    <round-button
      slot="left-button"
      outline
      @click="onCancel"
      name="applyToAllCardsDialogCancel"
      >Cancel</round-button
    >
    <round-button
      slot="right-button"
      @click="onApplyToAll"
      name="applyToAllCardsDialogContinue"
      >Yes, apply to all</round-button
    >
  </modal>
</template>

<script>
import Modal from '@/components/molecules/Modal.vue';
import Typography from '@/components/atoms/Typography.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';
import { BRAND } from '@/constants/form';

export default {
  components: {
    Modal,
    Typography,
    RoundButton
  },
  computed: {
    applyToAllCardsDialogOpen: {
      get() {
        return this.$store.state.ui.applyToAllCardsDialogOpen;
      },
      set(value) {
        this.$store.dispatch('ui/toggleApplyToAllCardsDialogOpen', value);
      }
    },
    brand: () => BRAND
  },
  methods: {
    onApplyToAll() {
      this.$store.dispatch('cardPreferences/applyActiveCardToAllCards');
      this.$store.dispatch('ui/toggleApplyToAllCardsDialogOpen', false);
    },
    onCancel() {
      this.$store.dispatch('ui/toggleApplyToAllCardsDialogOpen', false);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.dialog-header {
  margin-bottom: rem(30px);
}
</style>
