<template>
  <div :class="['box', { 'box--no-margin': noMargin }]">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    noMargin: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.box {
  background-color: #f8f8f8;
  border-radius: rem(10px);
  padding: rem(16px 30px 16px 16px);
  margin: rem(30px 0);

  &--no-margin {
    margin: 0;
  }
}
</style>
