<template>
  <header :class="['header', { 'header--drawer-open': drawerOpen }]">
    <icon-button class="home-button" @click="onHomeClick">
      <home-icon />
    </icon-button>
    <v-container class="container" fill-height>
      <v-layout>
        <v-flex xs12>
          <form-navigation v-if="$vuetify.breakpoint.mdAndUp" />
        </v-flex>
      </v-layout>
    </v-container>
    <text-button
      v-if="
        ($vuetify.breakpoint.md && !drawerOpen) || $vuetify.breakpoint.lgAndUp
      "
      :active="true"
      class="save-button"
      color="dark-blue"
      @click="onSaveAndShareClick"
      >Save and share</text-button
    >
  </header>
</template>

<script>
import FormNavigation from '@/components/molecules/FormNavigation.vue';
import HomeIcon from '@/assets/icons/home.svg';
import IconButton from '@/components/atoms/IconButton.vue';
import TextButton from '@/components/atoms/TextButton.vue';
import CaretIcon from '@/assets/icons/caret.svg';
import { LANDING } from '@/constants/routes';
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    FormNavigation,
    HomeIcon,
    CaretIcon,
    IconButton,
    TextButton
  },
  computed: {
    ...mapState({
      drawerOpen: state => state.ui.drawerOpen
    }),
    ...mapGetters({
      currentSectionName: 'navigation/currentSectionName'
    })
  },
  methods: {
    onHomeClick() {
      this.$router.push(LANDING);
    },
    onOpenMobileMenu() {
      this.$store.dispatch('ui/toggleMobileMenuOpen', true);
    },
    onSaveAndShareClick() {
      this.$store.dispatch('saver/saveDraft');
      this.$store.dispatch('ui/toggleShareDialog', true);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: $form-header-mobile-height;
  padding: rem(25px 35px 25px 70px);
  transition: all 0.3s ease;

  @include md {
    height: $form-header-height;
    background-color: $white;
  }

  &--drawer-open {
    @include md {
      width: calc(100% - #{$form-summary-sidebar-width});
    }
  }

  // &:after {
  //   content: '';
  //   position: absolute;
  //   top: 100%;
  //   left: 0;
  //   height: $form-header-gradient-height;
  //   width: 100%;
  //   background-image: linear-gradient(
  //     0deg,
  //     rgba(255, 255, 255, 0) 0%,
  //     #ffffff 100%
  //   );
  //   pointer-events: none;
  // }

  .save-button {
    position: absolute;
    right: rem(40px);
    top: 50%;
    transform: translate(0, -50%);
  }
}

.mobile-menu {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.container {
  padding: rem(0 10px);
}

.home-button {
  position: absolute;
  left: rem(15px);
  top: 50%;
  transform: translate(0, -50%);

  @include md {
    left: rem(30px);
  }

  /deep/ svg {
    path,
    g {
      fill: $mid-orange;
      stroke: $mid-orange;
    }
  }
}
</style>
