import { render, staticRenderFns } from "./CopyText.vue?vue&type=template&id=3cd2c400&scoped=true&"
import script from "./CopyText.vue?vue&type=script&lang=js&"
export * from "./CopyText.vue?vue&type=script&lang=js&"
import style0 from "./CopyText.vue?vue&type=style&index=0&id=3cd2c400&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cd2c400",
  null
  
)

export default component.exports