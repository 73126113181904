<template>
  <button
    :class="[
      'carousel-arrow',
      { 'carousel-arrow--reverse': reverse },
      { 'carousel-arrow--disabled': disabled }
    ]"
    type="button"
    @click="onClick"
  >
    <arrow-icon />
  </button>
</template>

<script>
import ArrowIcon from '@/assets/icons/arrow.svg';

export default {
  components: {
    ArrowIcon
  },
  props: {
    reverse: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick() {
      this.$emit('click');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.carousel-arrow {
  outline: 0;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-10px);
  }

  /deep/ svg {
    g {
      stroke: $mid-orange;
    }
  }

  &--reverse {
    transform: rotate(180deg);

    &:hover {
      transform: translateY(10px) rotate(180deg);
    }
  }

  &--disabled {
    pointer-events: none;

    /deep/ svg {
      g {
        stroke: $mid-charcoal;
      }
    }
  }
}
</style>
