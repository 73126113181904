import { req } from 'vuelidate/lib/validators/common';

export default value => {
  // Check if a value has been passed in
  if (!req(value)) {
    return true;
  }

  return /^[0-9]*$/.test(value);
};
