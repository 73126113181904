import api from '@/api';

export const state = () => ({
  deliveryAddress: null,
  deliveryAddressText: '',
  deliveryAddressLookupResponse: [],
  hasManualDeliveryAddress: false,
  contactNumber: '',
  invoiceEmail: '',
  invoiceDate: null,
  paymentPlan: null,
  bankId: '',
  bankAccountName: '',
  bankAccountNumber: '',
  hasBankSigningAuthority: false,
  hasAcceptedTermsAndConditions: false,
  deliveryAddressComplete: false,
  contactNumberComplete: false,
  invoiceEmailComplete: false,
  invoiceDateComplete: false,
  sectionComplete: false,
  bankDetailsComplete: false
});

export const mutations = {
  changeContactNumber(state, value) {
    state.contactNumber = value;
  },
  changeInvoiceEmail(state, value) {
    state.invoiceEmail = value;
  },
  changeInvoiceDate(state, value) {
    state.invoiceDate = value;
  },
  changeBankId(state, value) {
    state.bankId = value;
  },
  changeBankAccountName(state, value) {
    state.bankAccountName = value;
  },
  changeBankAccountNumber(state, value) {
    state.bankAccountNumber = value;
  },
  changeHasBankSigningAuthority(state, value) {
    state.hasBankSigningAuthority = value;
  },
  changeHasAcceptedTermsAndConditions(state, value) {
    state.hasAcceptedTermsAndConditions = value;
  },
  changeDeliveryAddress(state, value) {
    state.deliveryAddress = value;
  },
  changeDeliveryAddressText(state, value) {
    state.deliveryAddressText = value;
  },
  changeDeliveryAddressLookupResponse(state, value) {
    state.deliveryAddressLookupResponse = value;
  },
  changeHasManualDeliveryAddress(state, value) {
    state.hasManualDeliveryAddress = value;
  },
  changeDeliveryAddressComplete(state, value) {
    state.deliveryAddressComplete = value;
  },
  changeContactNumberComplete(state, value) {
    state.contactNumberComplete = value;
  },
  changeInvoiceEmailComplete(state, value) {
    state.invoiceEmailComplete = value;
  },
  changeBankDetailsComplete(state, value) {
    state.bankDetailsComplete = value;
  },
  changeInvoiceDateComplete(state, value) {
    state.invoiceDateComplete = value;
  },
  changePaymentPlan(state, value) {
    state.paymentPlan = value;
  },
  changeSectionComplete(state, value) {
    state.sectionComplete = value;
  }
};

export const actions = {
  changeContactNumber({ commit }, value) {
    commit('changeContactNumber', value);
  },
  changeInvoiceEmail({ commit }, value) {
    commit('changeInvoiceEmail', value);
  },
  changeInvoiceDate({ commit }, value) {
    commit('changeInvoiceDate', value);
  },
  changeInvoiceDateComplete({ commit }, value) {
    commit('changeInvoiceDateComplete', value);
  },
  changeBankId({ commit }, value) {
    commit('changeBankId', value);
  },
  changeBankAccountName({ commit }, value) {
    commit('changeBankAccountName', value);
  },
  changeBankAccountNumber({ commit }, value) {
    commit('changeBankAccountNumber', value);
  },
  changeHasBankSigningAuthority({ commit }, value) {
    commit('changeHasBankSigningAuthority', value);
  },
  changeHasAcceptedTermsAndConditions({ commit }, value) {
    commit('changeHasAcceptedTermsAndConditions', value);
  },
  changeHasManualDeliveryAddress({ commit }, value) {
    commit('changeHasManualDeliveryAddress', value);

    // Reset delivery address to null if using manual input
    if (value) {
      commit('changeDeliveryAddress', null);
    }
  },
  changeDeliveryAddress({ commit }, value) {
    commit('changeDeliveryAddress', value);
  },
  changeDeliveryAddressText({ commit, state }, value) {
    if (state.deliveryAddressText !== value) {
      commit('changeDeliveryAddressText', value);
    }
  },
  changeDeliveryAddressLookupResponse({ commit }, value) {
    commit('changeDeliveryAddressLookupResponse', value);
  },
  changeDeliveryAddressComplete({ commit }, value) {
    commit('changeDeliveryAddressComplete', value);
  },
  changeContactNumberComplete({ commit }, value) {
    commit('changeContactNumberComplete', value);
  },
  changeInvoiceEmailComplete({ commit }, value) {
    commit('changeInvoiceEmailComplete', value);
  },
  changeBankDetailsComplete({ commit }, value) {
    commit('changeBankDetailsComplete', value);
  },
  changePaymentPlan({ commit }, value) {
    commit('changePaymentPlan', value);
  },
  changeSectionComplete({ commit }, value) {
    commit('changeSectionComplete', value);
  },
  prepopulateInvoiceEmail({ dispatch, state, rootState }) {
    if (!state.invoiceEmail) {
      dispatch('changeInvoiceEmail', rootState.landing.email);
    }
  },
  async prepopulateDeliveryAddress({ dispatch, rootState }) {
    const { business } = rootState;

    const businessPhysicalAddress = business.physicalAddress || {
      fullAddress: business.physicalAddressText
    };

    dispatch('changeDeliveryAddress', businessPhysicalAddress);
    dispatch('changeDeliveryAddressText', business.physicalAddressText);
    dispatch('changeDeliveryAddressLookupResponse', [businessPhysicalAddress]);
  },
  async deliveryAddressLookup({ commit, dispatch, state }) {
    // Don't bother searching if there's no value
    if (!state.deliveryAddressText) {
      return;
    }

    try {
      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'deliveryAddressLookup', type: 'request' },
        { root: true }
      );

      const response = await api.searchAddresses(state.deliveryAddressText);

      //AO-746 filter out addresses that aren't deliverable
      const filteredResults = response.data.filter(result => {
        return (
          !!result.sourceDesc &&
          result.sourceDesc !== 'Physical' &&
          result.sourceDesc.indexOf('Not Delivered') === -1
        );
      });

      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'deliveryAddressLookup', type: 'success' },
        { root: true }
      );

      commit('changeDeliveryAddressLookupResponse', filteredResults);
    } catch (error) {
      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'deliveryAddressLookup', type: 'error' },
        { root: true }
      );

      // Switch to manual input
      dispatch('changeHasManualDeliveryAddress', true);
    }
  }
};

export const getters = {
  paymentPlanComplete(state, getters, rootState, rootGetters) {
    if (rootGetters['offer/skipPaymentPlan']) {
      return state.invoiceDateComplete;
    }
    return state.paymentPlan;
  }
};
