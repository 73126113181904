<template>
  <modal v-model="personalCardDialogOpen" center-buttons>
    <v-layout justify-center>
      <v-flex xs10 sm8 md10>
        <typography type="h3">
          Sorry {{ brand }} is for businesses only. Please check out our
          consumer offer <a :href="consumerCardLink">here</a>.
        </typography>
      </v-flex>
    </v-layout>

    <round-button slot="left-button" @click="onOk">OK</round-button>
  </modal>
</template>

<script>
import Modal from '@/components/molecules/Modal.vue';
import Typography from '@/components/atoms/Typography.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';
import { BRAND } from '@/constants/form';
import { Z_CONSUMER_CARD_LINK } from '@/constants/routes';

export default {
  components: {
    Modal,
    Typography,
    RoundButton
  },
  computed: {
    personalCardDialogOpen: {
      get() {
        return this.$store.state.ui.personalCardDialogOpen;
      },
      set(value) {
        this.$store.dispatch('ui/togglePersonalCardDialogOpen', value);
      }
    },
    brand: () => BRAND,
    consumerCardLink: () => Z_CONSUMER_CARD_LINK
  },
  methods: {
    onOk() {
      this.personalCardDialogOpen = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.dialog-header {
  margin-bottom: rem(30px);
}
</style>
