<template>
  <v-tooltip
    :top="top"
    :right="right"
    :bottom="bottom"
    :left="left"
    :content-class="contentClass"
    :close-delay="closeDelay"
    :value="value"
    @input="onInput"
  >
    <slot slot="activator" name="activator" />
    <slot />
  </v-tooltip>
</template>

<script>
export default {
  props: {
    top: {
      type: Boolean,
      default: false
    },
    bottom: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
    closeDelay: {
      type: Number,
      default: 10
    },
    value: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    contentClass() {
      let classes = ['tooltip'];

      if (this.top) {
        classes.push('tooltip--top');
      }

      if (this.right) {
        classes.push('tooltip--right');
      }

      if (this.bottom) {
        classes.push('tooltip--bottom');
      }

      if (this.left) {
        classes.push('tooltip--left');
      }

      return classes.join(' ');
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.tooltip {
  background-color: $white;
  padding: rem(20px 20px 15px);
  border-radius: rem(10px);
  max-width: 100%;
  box-shadow: 0 0 20px 0 rgba(169, 169, 169, 0.5);

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
  }

  &.menuable__content__active {
    opacity: 1 !important;
  }

  &--right {
    &:after {
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 12px solid $white;
      right: 100%;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  &--left {
    &:after {
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 12px solid $white;
      left: 100%;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  &--bottom {
    &:after {
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 12px solid $white;
      bottom: 100%;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  &--top {
    &:after {
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-top: 12px solid $white;
      top: 100%;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}
</style>
