import { req } from 'vuelidate/lib/validators/common';
import { parse, isValid } from 'date-fns/esm';

export default value => {
  // Check if a value has been passed in
  if (!req(value)) {
    return true;
  }

  if (value.length !== 10) {
    return false;
  }

  let date = parse(value, 'yyyy-MM-dd', new Date());
  return isValid(date);
};
