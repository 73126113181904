export const state = () => ({
  fullName: '',
  email: '',
  contactNumber: ''
});

export const mutations = {
  changeFullName(state, value) {
    state.fullName = value;
  },
  changeEmail(state, value) {
    state.email = value;
  },
  changeContactNumber(state, value) {
    state.contactNumber = value;
  }
};

export const actions = {
  changeFullName({ commit }, value) {
    commit('changeFullName', value);
  },
  changeEmail({ commit }, value) {
    commit('changeEmail', value);
  },
  changeContactNumber({ commit }, value) {
    commit('changeContactNumber', value);
  },
  prepopulateFullName({ dispatch, rootState, state }) {
    if (!state.fullName) {
      dispatch('changeFullName', rootState.landing.name);
    }
  },
  prepopulateEmail({ dispatch, rootState, state }) {
    if (!state.email) {
      dispatch('changeEmail', rootState.landing.email);
    }
  }
};

export const getters = {};
