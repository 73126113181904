<template>
  <button
    :class="[
      'carousel-button',
      { 'carousel-button--active': active },
      { 'carousel-button--disabled': disabled }
    ]"
    type="button"
    @click="onClick"
  />
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick() {
      this.$emit('click');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.carousel-button {
  width: rem(6px);
  height: rem(6px);
  border-radius: 50%;
  background-color: $dark-blue;
  border: 0;
  outline: 0;
  margin: rem(5px 0);
  transition: all 0.3s ease;

  &--active,
  &:hover {
    transform: scale(1.67);
  }

  &--disabled {
    pointer-events: none;
    background-color: $mid-charcoal;
  }
}
</style>
