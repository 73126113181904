import api from '@/api';
import { CREDIT_CHECK_ASSESS } from '@/constants/form';
import { SUBMIT_PG_ERROR } from '@/constants/errors';

export const state = () => ({
  isCompanyDirector: null,
  readRepresentativeRequirements: false,
  legalName: '',
  gender: '',
  dateOfBirth: null,
  driversLicenseNumber: '',
  driversLicenseVersionNo: '',
  residentialAddress: null, // Address selected from lookup
  residentialAddressText: '',
  hasManualResidentialAddress: false,
  residentialAddressLookupResponse: [],
  agreedToCreditCheck: false,
  agreedToPersonalGuarantee: false,
  personalGuaranteeEmail: '',
  creditCheckResult: CREDIT_CHECK_ASSESS,
  legalNameComplete: false,
  dateOfBirthComplete: false,
  driversLicenseComplete: false,
  residentialAddressComplete: false,
  sectionComplete: false
});

export const mutations = {
  changeIsCompanyDirector(state, value) {
    state.isCompanyDirector = value;
  },
  changeLegalName(state, value) {
    state.legalName = value;
  },
  changeGender(state, value) {
    state.gender = value;
  },
  changeDateOfBirth(state, value) {
    state.dateOfBirth = value;
  },
  changeDriversLicenseNumber(state, value) {
    state.driversLicenseNumber = value;
  },
  changeDriversLicenseVersionNo(state, value) {
    state.driversLicenseVersionNo = value;
  },
  changeResidentialAddress(state, value) {
    state.residentialAddress = value;
  },
  changeResidentialAddressText(state, value) {
    state.residentialAddressText = value;
  },
  changeHasManualResidentialAddress(state, value) {
    state.hasManualResidentialAddress = value;
  },
  changeResidentialAddressLookupResponse(state, value) {
    state.residentialAddressLookupResponse = value;
  },
  changeAgreedToCreditCheck(state, value) {
    state.agreedToCreditCheck = value;
  },
  changeSectionComplete(state, value) {
    state.sectionComplete = value;
  },
  changeLegalNameComplete(state, value) {
    state.legalNameComplete = value;
  },
  changeDateOfBirthComplete(state, value) {
    state.dateOfBirthComplete = value;
  },
  changeDriversLicenseComplete(state, value) {
    state.driversLicenseComplete = value;
  },
  changeResidentialAddressComplete(state, value) {
    state.residentialAddressComplete = value;
  },
  changeReadRepresentativeRequirements(state, value) {
    state.readRepresentativeRequirements = value;
  },
  changePersonalGuaranteeEmail(state, value) {
    state.personalGuaranteeEmail = value;
  },
  changeAgreedToPersonalGuarantee(state, value) {
    state.agreedToPersonalGuarantee = value;
  },
  changeCreditCheckResult(state, value) {
    state.creditCheckResult = value;
  }
};

export const actions = {
  changeIsCompanyDirector({ commit }, value) {
    commit('changeIsCompanyDirector', value);
  },
  changeLegalName({ commit, dispatch }, value) {
    commit('changeLegalName', value);
    dispatch('prepopulateResidentialAddress');
  },
  changeGender({ commit }, value) {
    commit('changeGender', value);
  },
  changeDateOfBirth({ commit }, value) {
    commit('changeDateOfBirth', value);
  },
  changeDateOfBirthComplete({ commit }, value) {
    commit('changeDateOfBirthComplete', value);
  },
  changeDriversLicenseNumber({ commit }, value) {
    commit('changeDriversLicenseNumber', value);
  },
  changeDriversLicenseVersionNo({ commit }, value) {
    commit('changeDriversLicenseVersionNo', value);
  },
  changeResidentialAddress({ commit }, value) {
    commit('changeResidentialAddress', value);
  },
  changeResidentialAddressText({ commit, state }, value) {
    if (value !== state.residentialAddressText) {
      commit('changeResidentialAddressText', value);
    }
  },
  changeResidentialAddressLookupResponse({ commit }, value) {
    commit('changeResidentialAddressLookupResponse', value);
  },
  changeHasManualResidentialAddress({ commit }, value) {
    commit('changeHasManualResidentialAddress', value);

    // Reset residential address to null if using manual input
    if (value) {
      commit('changeResidentialAddress', null);
    }
  },
  changeAgreedToCreditCheck({ commit }, value) {
    commit('changeAgreedToCreditCheck', value);
  },
  changeSectionComplete({ commit, dispatch }, value) {
    if (value) {
      // Prepopulate email
      dispatch('finalDetails/prepopulateInvoiceEmail', null, { root: true });
    }

    commit('changeSectionComplete', value);
  },
  changeLegalNameComplete({ commit }, value) {
    commit('changeLegalNameComplete', value);
  },
  changeDriversLicenseComplete({ commit }, value) {
    commit('changeDriversLicenseComplete', value);
  },
  changeResidentialAddressComplete({ commit }, value) {
    commit('changeResidentialAddressComplete', value);
  },
  changeReadRepresentativeRequirements({ commit }, value) {
    commit('changeReadRepresentativeRequirements', value);
  },
  changePersonalGuaranteeEmail({ commit }, value) {
    commit('changePersonalGuaranteeEmail', value);
  },
  changeAgreedToPersonalGuarantee({ commit }, value) {
    commit('changeAgreedToPersonalGuarantee', value);
  },
  changeCreditCheckResult({ commit }, value) {
    commit('changeCreditCheckResult', value);
  },
  prepopulateResidentialAddress({ dispatch, rootState }) {
    const { business } = rootState;
    const directorAddress = business.business?.roles.find(role => `${role.rolePerson.firstName} ${role.rolePerson.lastName}` == rootState.credit.legalName)?.roleAddress.at(0);
    console.log(directorAddress);
    // If directior exists
    if (directorAddress) {
      let businessPhysicalAddress;

      businessPhysicalAddress = {
        fullAddress: directorAddress.fullAddress,
        dpid: directorAddress.pafId
      };

      if (businessPhysicalAddress) {
        dispatch('changeResidentialAddress', businessPhysicalAddress);
        dispatch(
          'changeResidentialAddressText',
          businessPhysicalAddress.fullAddress
        );
        dispatch('changeResidentialAddressLookupResponse', [
          businessPhysicalAddress
        ]);
      }
    }
  },
  prepopulatePersonalGuaranteeEmail({ dispatch, state, rootState }) {
    if (!state.personalGuaranteeEmail) {
      dispatch('changePersonalGuaranteeEmail', rootState.landing.email);
    }
  },
  async residentialAddressLookup({ commit, dispatch, state }) {
    // Don't bother searching if there's no value
    if (!state.residentialAddressText) {
      return;
    }

    try {
      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'residentialAddressLookup', type: 'request' },
        { root: true }
      );

      const response = await api.searchAddresses(state.residentialAddressText);

      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'residentialAddressLookup', type: 'success' },
        { root: true }
      );

      commit('changeResidentialAddressLookupResponse', response.data);
    } catch (error) {
      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'residentialAddressLookup', type: 'error' },
        { root: true }
      );

      // Switch to manual input
      dispatch('changeHasManualResidentialAddress', true);
    }
  },
  async submitPersonalGuarantee({
    commit,
    rootState,
    state,
    dispatch,
    rootGetters
  }) {
    try {
      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'submitPersonalGuarantee', type: 'request' },
        { root: true }
      );

      await api.submitPersonalGuarantee(rootState.saver.applicationId, {
        ...state
      });

      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'submitPersonalGuarantee', type: 'success' },
        { root: true }
      );
    } catch (error) {
      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'submitPersonalGuarantee', type: 'error' },
        { root: true }
      );

      dispatch(
        'ui/addErrorToast',
        {
          id: 'failed-personal-guarantee',
          text: SUBMIT_PG_ERROR
        },
        { root: true }
      );
    }
  }
};
