<template>
  <v-text-field
    :label="label"
    :name="name"
    :browser-autocomplete="autocomplete"
    :value="value"
    :error="error"
    :rules="rules"
    :error-messages="errorMessages"
    :prefix="prefix"
    :suffix="suffix"
    :mask="mask"
    :placeholder="placeholder"
    :hint="hint"
    :type="type"
    :class="[
      'text-input',
      `text-input--${color}`,
      { 'text-input--bold': bold },
      { 'text-input--no-label': !label },
      { 'text-input--no-margin': noMargin }
    ]"
    :validate-on-blur="validateOnBlur"
    :pattern="pattern"
    :maxlength="maxlength"
    v-on="$listeners"
  />
</template>

<script>
export default {
  props: {
    autocomplete: {
      type: String,
      default: 'off'
    },
    hint: {
      type: String,
      default: ''
    },
    mask: {
      type: String,
      default: ''
    },
    maxlength: {
      type: Number,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    value: {
      type: null,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    pattern: {
      type: String,
      default: null
    },
    errorMessages: {
      type: Array,
      default: () => []
    },
    prefix: {
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: ''
    },
    bold: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    noMargin: {
      type: Boolean,
      default: false
    },
    validateOnBlur: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'black',
      validators: value => {
        return ['black', 'white'].includes(value);
      }
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

:not(.v-input--is-disabled).text-input--white.v-input input,
:not(.v-input--is-disabled).text-input--white.v-input textarea {
  color: currentColor !important;
}

.text-input {
  font-size: rem(20px);
  line-height: rem(30px);
  margin-bottom: rem(25px);

  &--no-margin {
    margin-bottom: 0;
  }

  &--no-label {
    margin-top: 0;
  }

  &--bold {
    font-weight: $weight-bold;
  }

  &--black {
    color: $charcoal !important;

    .v-input__slot {
      &:before {
        border-color: $mid-charcoal !important;
      }

      &:hover {
        &:before {
          border-color: $charcoal !important;
        }
      }
    }
  }

  &--white {
    color: $white !important;

    &.v-text-field > .v-input__control > .v-input__slot:before,
    &.v-text-field > .v-input__control > .v-input__slot::before {
      border-color: currentColor !important;
    }

    .v-input__slot {
      &:before {
        border-color: $white !important;
      }
    }

    &.primary--text {
      color: $white !important;
    }

    label {
      color: currentColor !important;
    }
  }

  .v-messages {
    min-height: rem(15px);
    font-size: rem(15px);
    color: $mid-charcoal !important;

    &.error--text {
      color: $error-color !important;
    }
  }

  input {
    color: currentColor !important;

    // Transparent autocomplete background
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      animation: autofill 0s forwards;
    }

    @keyframes autofill {
      100% {
        background: transparent;
        color: inherit;
      }
    }
  }

  .v-text-field {
    &__prefix,
    &__suffix {
      color: currentColor;
      padding: 0;
    }
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &.error--text {
    color: $error-color !important;

    .v-input__slot {
      &:before {
        border-color: $error-color !important;
      }

      &:after {
        color: $error-color !important;
      }
    }
  }

  .v-input__slot {
    &:before {
      border-width: 1px;
    }

    &:after {
      color: currentColor;
      border-width: 1px;
    }
  }

  .v-label {
    color: currentColor;
    font-size: rem(20px);
    font-weight: $weight-regular;
    cursor: text;

    &--active {
      font-weight: $weight-medium;
      transform: translateY(-20px) scale(0.6);
    }
  }
}
</style>
