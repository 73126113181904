import api from '@/api';
import { toAbsoluteUrl } from '@/helpers/url';
import cloneDeep from 'lodash.clonedeep';
import {
  SAVE_DRAFT_ERROR,
  SAVE_APPLICATION_ERROR,
  LOAD_DRAFT_ERROR,
  SHARE_DRAFT_ERROR
} from '@/constants/errors';
import {
  APPLICATION_TYPE,
  APPLICATION_TYPE_Z_BUSINESS
} from '@/constants/application';
import { BRAND } from '@/constants/form';

export const state = () => ({
  saveLink: '',
  applicationId: '',
  applicationType: '',
  emails: ['']
});

export const mutations = {
  changeSaveLink(state, value) {
    state.saveLink = value;
  },
  changeApplicationId(state, value) {
    state.applicationId = value;
  },
  changeEmails(state, { index, value }) {
    state.emails = [
      ...state.emails.slice(0, index),
      value,
      ...state.emails.slice(index + 1)
    ];
  },
  addEmail(state, value = '') {
    state.emails = [...state.emails, value];
  },
  changeApplicationType(state, value) {
    state.applicationType = value;
  }
};

export const actions = {
  changeSaveLink({ commit }, value) {
    commit('changeSaveLink', value);
  },
  changeApplicationId({ commit }, value) {
    commit('changeApplicationId', value);
  },
  changeApplicationType({ commit }, value) {
    commit('changeApplicationType', value);
  },
  changeEmails({ commit }, { index, value }) {
    commit('changeEmails', { index, value });
  },
  addEmail({ commit }, value = '') {
    commit('addEmail', value);
  },
  prepopulateEmail({ dispatch, rootState, state }) {
    if (state.emails.length && !state.emails[0]) {
      dispatch('changeEmails', { index: 0, value: rootState.landing.email });
    }
  },
  async emailSaveLinks({ commit, state, dispatch, getters }) {
    try {
      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'emailSaveLinks', type: 'request' },
        { root: true }
      );

      await api.shareApplication(
        state.applicationId,
        state.emails.filter(email => email && email !== '')
      );

      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'emailSaveLinks', type: 'success' },
        { root: true }
      );
    } catch (error) {
      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'emailSaveLinks', type: 'error' },
        { root: true }
      );

      dispatch(
        'ui/addErrorToast',
        {
          id: 'failed-email-save-link',
          text: SHARE_DRAFT_ERROR
        },
        { root: true }
      );
    }
  },
  /**
   * This attempts to load the form from the server
   */
  async loadSavedForm({ commit, dispatch }, params) {
    try {
      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'loadSavedForm', type: 'request' },
        { root: true }
      );

      if (!params.applicationId) {
        throw new Error('No applicationId set');
      }

      if (!params.draftId) {
        throw new Error('No draftId set');
      }

      // Attempt to load from server
      let response;
   
      response = await api.loadSavedForm(
        params.applicationId,
        params.draftId
      );
      
      const draftModel = response.data;
      if (draftModel.isExpired) {
        dispatch(
          'ui/addToast',
          {
            id: 'failed-load-saved-form',
            text:
              'Sorry, the draft you are using has expired. Please start a new application form.'
          },
          { root: true }
        );
      } else {
        commit('changeApplicationId', params.applicationId);
        commit('changeApplicationType', params.type); // Not sure this is passed in...
        this.replaceState(draftModel.state.rootState);
      }

      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'loadSavedForm', type: 'success' },
        { root: true }
      );
    } catch (error) {
      dispatch(
        'ui/addErrorToast',
        {
          id: 'failed-load-saved-form',
          text: LOAD_DRAFT_ERROR
        },
        { root: true }
      );

      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'loadSavedForm', type: 'error' },
        { root: true }
      );
    }
  },
  /*
    Create our initial application..
  */
  async createApplication(
    { commit, dispatch, rootState },
    applicationType = APPLICATION_TYPE[APPLICATION_TYPE_Z_BUSINESS]
  ) {
    try {
      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'createApplication', type: 'request' },
        { root: true }
      );

      let response;

      response = await api.createApplication({
        fullName: rootState.landing.name,
        email: rootState.landing.email,
        receiveCorrespondence: rootState.landing.acceptedUpdates,
        type: applicationType
      });
    

      const applicationId = response.data.externalId;

      commit('changeApplicationId', applicationId);
      commit('changeApplicationType', response.data.type);

      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'createApplication', type: 'success' },
        { root: true }
      );
    } catch (error) {
      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'createApplication', type: 'error', error },
        { root: true }
      );

      dispatch(
        'ui/addErrorToast',
        {
          id: 'failed-create-application',
          text: SAVE_APPLICATION_ERROR
        },
        { root: true }
      );
    }
  },
  async submitApplication({ commit, rootState, dispatch, getters }) {
    try {
      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'submitApplication', type: 'request' },
        { root: true }
      );

      // Build application payload to send
      const landing = rootState.landing;
      
      const application = {
        fullName: landing.name,
        email: landing.email,
        receiveCorrespondence: landing.acceptedUpdates,
        businessDetails: rootState.business,
        loyaltyDetails: rootState.offer,
        creditDetails: rootState.credit,
        finalDetails: rootState.finalDetails
      };

      await api.submitApplication(rootState.saver.applicationId, application);
    
      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'submitApplication', type: 'success' },
        { root: true }
      );
    } catch (error) {
      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'submitApplication', type: 'error' },
        { root: true }
      );

      dispatch(
        'ui/addErrorToast',
        {
          id: 'failed-submit-application',
          text: SAVE_APPLICATION_ERROR,
          error
        },
        { root: true }
      );
    }
  },
  async submitRequestCallback({ commit, rootState, dispatch, getters }) {
    try {
      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'submitRequestCallback', type: 'request' },
        { root: true }
      );

      // Build application payload to send
      const landing = rootState.landing;
      const application = {
        fullName: landing.name,
        email: landing.email,
        receiveCorrespondence: landing.acceptedUpdates,
        businessDetails: rootState.business,
        callbackDetails: rootState.specialRates
      };
      
      await api.submitRequestCallback(
        rootState.saver.applicationId,
        application
      );

      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'submitRequestCallback', type: 'success' },
        { root: true }
      );
    } catch (error) {
      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'submitRequestCallback', type: 'error' },
        { root: true }
      );

      dispatch(
        'ui/addErrorToast',
        {
          id: 'failed-submit-request-callback',
          text: SAVE_APPLICATION_ERROR,
          error
        },
        { root: true }
      );
    }
  },
  async saveDraft({ commit, getters, rootState, dispatch, state }) {
    try {
      const draftState = cloneDeep(rootState);
      // need to reset some state to prevent dialogs/toasts being opened when rehydrating state
      draftState.ui.shareDialogOpen = false;
      draftState.ui.toasts = [];

      let response = await api.saveDraft(state.applicationId, draftState);

      const draftId = response.data.draftId;

      const draftPath = `/form/${state.applicationId}/draft/${draftId}`;

      const draftUrl = toAbsoluteUrl(window.location.origin, draftPath);

      commit('changeSaveLink', draftUrl);
    } catch (error) {
      dispatch(
        'ui/addErrorToast',
        {
          id: 'failed-save-draft',
          text: SAVE_DRAFT_ERROR
        },
        { root: true }
      );
    }
  }
};

export const getters = {
  brand: state => {
    return BRAND;
  }
};
