<template>
  <div
    :class="[
      'z-card',
      { 'z-card--orange-shadow': orangeShadow },
      { 'z-card--removable': removable },
      { 'z-card--black-shadow': blackShadow }
    ]"
    @click="onClick"
  >
    <icon-button
      v-if="removable"
      class="remove-button"
      @click="onRemove"
      name="removeCardIcon"
    >
      <remove-icon class="remove-icon" />
    </icon-button>
    <logo class="logo" />
    <div
      v-if="$vuetify.breakpoint.smAndUp || !hasError"
      class="label"
      v-bind:class="{ 'driver-vehicle-label-scale': scaleLabel }"
    >
      {{ label }}
    </div>
    <error-message v-else class="error">
      Incomplete
    </error-message>
    <div class="bars">
      <div v-if="!text" class="bar" />
      <typography v-else no-margin medium>
        {{ text }}
      </typography>
    </div>
    <div class="bars">
      <div class="bar" />
      <div class="bar" />
      <div class="bar" />
    </div>
  </div>
</template>

<script>
import Typography from '@/components/atoms/Typography.vue';
import Logo from '@/components/atoms/Logo.vue';
import RemoveIcon from '@/assets/icons/remove.svg';
import IconButton from '@/components/atoms/IconButton.vue';
import ErrorMessage from '@/components/molecules/ErrorMessage.vue';

export default {
  components: {
    Typography,
    Logo,
    IconButton,
    RemoveIcon,
    ErrorMessage
  },
  props: {
    blackShadow: {
      type: Boolean,
      default: false
    },
    orangeShadow: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    removable: {
      type: Boolean,
      default: false
    },
    scaleLabel: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
    onRemove() {
      this.$emit('remove');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.z-card {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: flex-end;
  background-image: linear-gradient(-180deg, #271d90 0%, $dark-blue 100%);
  border-radius: rem(15px);
  padding: rem(25px 30px);
  width: rem(230px);
  height: rem(150px);
  max-width: 100%;
  transition: all 0.3s ease;

  &--orange-shadow {
    box-shadow: 0 0 20px 0 #81330e;
    margin: rem(15px);
  }

  &--black-shadow {
    box-shadow: 0 20px 40px 0 rgba(129, 129, 129, 0.5);
  }

  &--removable {
    cursor: pointer;
  }

  .error {
    position: absolute;
    top: rem(20px);
    right: rem(30px);
  }
}

.remove-button {
  position: absolute;
  top: rem(-10px);
  right: rem(-10px);
}

.bars {
  display: flex;
  margin-top: rem(12px);

  .bar {
    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.bar {
  background-color: #463bc3;
  width: 100%;
  height: rem(11px);
  border-radius: rem(7px);
  margin: rem(0 5px);
}

.logo {
  position: absolute;
  top: rem(20px);
  left: rem(30px);
  width: rem(30px);
  height: rem(30px);
}

.label {
  position: absolute;
  top: rem(20px);
  right: rem(30px);
  border-radius: rem(7px);
  background: #3a32ac;
  text-transform: uppercase;
  font-size: rem(13px);
  color: $white;
  padding: rem(3px 10px);
  letter-spacing: rem(2px);
}
.driver-vehicle-label-scale {
  transform: scale(0.8);
  right: 0.875rem;
}
</style>
