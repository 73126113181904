<template>
  <v-btn
    :class="['btn', `btn--${color}`]"
    :disabled="disabled || loading"
    :outline="outline"
    :to="to"
    :type="type"
    depressed
    round
    @click="$emit('click')"
    :name="name"
  >
    <slot v-if="!loading" />
    <loading-dots v-if="loading" />
  </v-btn>
</template>

<script>
import LoadingDots from '@/components/atoms/LoadingDots.vue';

export default {
  components: {
    LoadingDots
  },
  props: {
    color: {
      type: String,
      default: 'orange',
      validator: value => {
        return ['orange', 'white'].includes(value);
      }
    },
    type: {
      type: String,
      default: 'button'
    },
    outline: {
      type: Boolean,
      default: false
    },
    to: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.btn {
  margin: 0;
  min-height: rem(43px);
  min-width: rem(100px);
  width: 100%;
  max-width: 100%;
  padding: rem(0 20px);
  font-size: rem(16px);
  font-weight: $weight-bold;
  text-transform: none;

  &:before {
    opacity: 0;
  }

  &:hover {
    text-decoration: none;
  }

  @include md {
    min-height: rem(46px);
    min-width: rem(170px);
    width: auto;
    padding: rem(0 20px);
    font-size: rem(18px);
  }

  &.v-btn--outline {
    border-width: rem(2px);
  }

  &--orange {
    background-color: $orange !important;
    color: $white;

    &:hover {
      background-color: $mid-orange !important;
    }

    &:active {
      background-color: #f1580f !important;
    }

    &:focus {
      background-color: $orange !important;
      box-shadow: 0 0 4px 0 #4a90e2;
    }

    &.v-btn--outline {
      background-color: transparent !important;
      color: $orange;

      &:hover {
        background-color: transparent !important;
        color: $mid-orange;
      }

      &:active {
        color: #f1580f;
      }
    }
  }

  &--white {
    background-color: $white !important;
    // Color based on background color of page. Not always orange. Needs to inherit color
    color: currentColor;

    &:hover {
      background-color: $white !important;
    }

    &:active {
      background-color: $white !important;
    }

    &:focus {
      background-color: $white !important;
      box-shadow: 0 0 4px 0 #4a90e2;
    }

    &.v-btn--outline {
      background-color: transparent !important;
      color: $white;

      &:hover {
        background-color: transparent !important;
        color: $white;
        border-color: currentColor;
      }

      &:active {
        color: currentColor;
      }
    }
  }

  &.v-btn--disabled.v-btn:not(.v-btn--icon) {
    background-color: #e4e4e4 !important;
    color: $white !important;

    &.v-btn--outline {
      background-color: transparent !important;
      border-color: $light-charcoal;
      color: $mid-charcoal !important;
    }
  }
}
</style>
