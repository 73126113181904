<template>
  <v-dialog
    :value="value"
    content-class="dialog-wrapper"
    width="700px"
    @input="onInput"
    :persistent="persistent"
  >
    <div class="dialog">
      <icon-button class="close-button" @click="onClose">
        <close-icon />
      </icon-button>
      <div class="dialog-content">
        <slot />
      </div>
      <footer
        v-if="!!$slots['left-button'] || !!$slots['right-button']"
        :class="['dialog-footer', { 'dialog-footer--center': centerButtons }]"
      >
        <slot name="left-button" />
        <slot name="middle-button" />
        <slot name="right-button" />
      </footer>
    </div>
  </v-dialog>
</template>

<script>
/**
 * This file is called Modal because of existing html element 'dialog'
 */
import IconButton from '@/components/atoms/IconButton.vue';
import CloseIcon from '@/assets/icons/close.svg';

export default {
  components: {
    IconButton,
    CloseIcon
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    centerButtons: {
      type: Boolean,
      default: false
    },
    persistent: {
      type: Boolean,
      default: false
    },
    preventClose: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    },
    onClose() {
      if (!this.preventClose) {
        this.$emit('input', false);
      }
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.close-button {
  position: absolute;
  top: rem(15px);
  right: rem(15px);
}

.dialog-wrapper {
  background-color: $white;
  display: flex;
  border-radius: rem(13px);
}

.dialog {
  margin: auto;
  position: relative;
  width: 100%;
  padding-top: rem(20px);
}

.dialog-content {
  text-align: center;
  padding: rem(10px 20px 30px 20px);

  @include md {
    padding: rem(10px 85px 30px 85px);
  }
}

.dialog-footer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: rem(15px 30px);
  border-top: 1px solid $light-charcoal;

  &--center {
    justify-content: center;
  }

  @include sm {
    padding: rem(32px 150px);
  }

  @include md {
    padding: rem(30px 60px);
    flex-direction: row;
  }

  > .btn {
    @include sm-and-down {
      margin: rem(10px 0) !important;

      &:first-of-type {
        order: 2;
      }

      &:last-of-type {
        order: 1;
      }
    }
  }
}
</style>
