
import { LIMITED_COMPANY } from '@/constants/form';
import api from '@/api';
import { calculateAge } from '@/helpers/date';

export const state = () => ({
  businessType: '',
  fuelCardBusinessUseOnly: null,
  fuelCardBusinessUseOnlyDeclare: false,
  fuelCardBusinessUseOnlyConfirm: false,
  businessUseOnly: false,
  business: null,
  businessName: '',
  hasManualInputBusiness: false,
  businessLookupResponse: [],
  hasCompletedDetailsLookup: false,
  hasDifferentTradingName: false,
  tradingName: '',
  physicalAddress: null,
  physicalAddressText: '',
  hasManualPhysicalAddress: false,
  physicalAddressLookupResponse: [],
  fuelAmount: 500,
  businessNameComplete: false,
  physicalAddressComplete: false,
  sectionComplete: false
});

export const mutations = {
  changeBusinessType(state, value) {
    state.businessType = value;
  },
  changeHasManualInputBusiness(state, value) {
    state.hasManualInputBusiness = value;
  },
  changeBusinessLookupResponse(state, value) {
    state.businessLookupResponse = value;
  },
  changeBusiness(state, value) {
    state.business = value;
  },
  changeFuelCardBusinessUseOnly(state, value) {
    state.fuelCardBusinessUseOnly = value;
  },
  changeFuelCardBusinessUseOnlyDeclare(state, value) {
    state.fuelCardBusinessUseOnlyDeclare = value;
  },
  changeFuelCardBusinessUseOnlyConfirm(state, value) {
    state.fuelCardBusinessUseOnlyConfirm = value;
  },
  changeBusinessUseOnly(state, value) {
    state.businessUseOnly = value;
  },
  changeBusinessTypeComplete(state, value) {
    state.changeBusinessTypeComplete = value;
  },
  changeFuelAmount(state, value) {
    state.fuelAmount = value;
  },
  changeBusinessName(state, value) {
    state.businessName = value;
  },
  changeTradingName(state, value) {
    state.tradingName = value;
  },
  changeHasDifferentTradingName(state, value) {
    state.hasDifferentTradingName = value;
  },
  resetPhysicalAddress(state) {
    state.physicalAddress = null;
    state.physicalAddressText = '';
    state.physicalAddressLookupResponse = [];
  },
  changePhysicalAddress(state, value) {
    state.physicalAddress = value;
  },
  changePhysicalAddressText(state, value) {
    state.physicalAddressText = value;
  },
  changeHasManualPhysicalAddress(state, value) {
    state.hasManualPhysicalAddress = value;
  },
  changePhysicalAddressLookupResponse(state, value) {
    state.physicalAddressLookupResponse = value;
  },
  changeBusinessNameComplete(state, value) {
    state.businessNameComplete = value;
  },
  changePhysicalAddressComplete(state, value) {
    state.physicalAddressComplete = value;
  },
  changeSectionComplete(state, value) {
    state.sectionComplete = value;
  },
  changeHasCompletedDetailsLookup(state, value) {
    state.hasCompletedDetailsLookup = value;
  }
};

export const actions = {
  changeBusinessType({ commit }, value) {
    commit('changeBusinessType', value);
    //reset business use only if the business type is changed
    commit('changeFuelCardBusinessUseOnly', null);
  },
  changeBusinessNameComplete({ commit }, value) {
    commit('changeBusinessNameComplete', value);
  },
  changePhysicalAddressComplete({ commit }, value) {
    commit('changePhysicalAddressComplete', value);
  },
  changeHasManualInputBusiness({ commit }, value) {
    commit('changeHasManualInputBusiness', value);

    // Reset business to null if using manual input
    if (value) {
      commit('changeBusiness', null);
    }
  },
  changeBusinessLookupResponse({ commit }, value) {
    commit('changeBusinessLookupResponse', value);
  },
  async changeBusiness({ commit, dispatch }, value) {
    commit('changeHasCompletedDetailsLookup', false);
    commit('changeBusiness', value);

    // Try enriching the business details
    if (value) {
      const businessDetails = await dispatch('getBusinessDetails', value);

      const dpid =
        businessDetails.data &&
        businessDetails.data.address &&
        businessDetails.data.address.pafId;

      if (dpid) {
        // pre-populate card delivery address if its a deliverable address
        await dispatch('prePopulateDeliveryAddress', dpid);
      }
    } else {
      commit('changeHasCompletedDetailsLookup', true);
    }
  },
  async prePopulateDeliveryAddress({ dispatch, state }, dpid) {
    if (!dpid) {
      dpid =
        state.business &&
        state.business.address &&
        state.business.address.pafId;
    }

    if (dpid) {
      // prepopulate card delivery address if its a deliverable address
      const address = await api.getAddress(dpid);
      const isDeliverable = address.data && address.data.deliverable;

      if (isDeliverable === 'Y') {
        // prepopulate
        dispatch('finalDetails/changeDeliveryAddress', state.physicalAddress, {
          root: true
        });
        dispatch(
          'finalDetails/changeDeliveryAddressText',
          state.physicalAddressText,
          { root: true }
        );
        dispatch(
          'finalDetails/changeDeliveryAddressLookupResponse',
          [state.physicalAddress],
          { root: true }
        );
      } else {
        // reset values back to default
        dispatch('finalDetails/changeDeliveryAddress', null, { root: true });
        dispatch('finalDetails/changeDeliveryAddressText', '', {
          root: true
        });
        dispatch('finalDetails/changeDeliveryAddressLookupResponse', [], {
          root: true
        });
      }
    }
  },
  async getBusinessDetails({ commit, dispatch }, value) {
    let response;
    if (value && value.nzbn) {
      response = await api.getBusiness(value.nzbn);

      if (response && response.data) {
        commit('changeBusiness', response.data);
        dispatch('prepopulatePhysicalAddress');
      }
    }

    commit('changeHasCompletedDetailsLookup', true);
    return response;
  },
  changeFuelCardBusinessUseOnly({ commit }, value) {
    commit('changeFuelCardBusinessUseOnly', value);
  },
  changeFuelCardBusinessUseOnlyDeclare({ commit }, value) {
    commit('changeFuelCardBusinessUseOnlyDeclare', value);
  },
  changeFuelCardBusinessUseOnlyConfirm({ commit }, value) {
    commit('changeFuelCardBusinessUseOnlyConfirm', value);
  },
  changeBusinessUseOnly({ commit }, value) {
    commit('changeBusinessUseOnly', value);
  },
  changeBusinessTypeComplete({ commit }, value) {
    commit('changeBusinessTypeComplete', value);
  },
  changeBusinessName({ commit, dispatch, state }, value) {
    if (state.businessName !== value) {
      commit('changeBusinessName', value);
      // Clear out any previously entered director details
      dispatch('credit/changeLegalName', null, { root: true });
    }
  },
  changeTradingName({ commit }, value) {
    commit('changeTradingName', value);
  },
  changeFuelAmount({ commit }, value) {
    commit('changeFuelAmount', value);
  },
  changeHasDifferentTradingName({ commit }, value) {
    commit('changeHasDifferentTradingName', value);

    // Clear trading name when toggling
    commit('changeTradingName', '');
  },
  resetPhysicalAddress({ commit }) {
    commit('resetPhysicalAddress');
  },
  changePhysicalAddress({ commit }, value) {
    commit('changePhysicalAddress', value);
  },
  changePhysicalAddressText({ commit, state }, value) {
    if (state.physicalAddressText !== value) {
      commit('changePhysicalAddressText', value);
    }
  },
  changeHasManualPhysicalAddress({ commit }, value) {
    commit('changeHasManualPhysicalAddress', value);

    // Reset physical address object if using manual physical address
    if (value) {
      commit('changePhysicalAddress', null);
    }
  },
  changePhysicalAddressLookupResponse({ commit }, value) {
    commit('changePhysicalAddressLookupResponse', value);
  },
  changeSectionComplete({ commit }, value) {
    commit('changeSectionComplete', value);
  },
  prepopulatePhysicalAddress({ dispatch, state }) {
    const { business } = state;

    if (!state.hasManualPhysicalAddress && business.address) {
      const physicalAddressText = business.address.fullAddress;
      const physicalAddress = {
        fullAddress: physicalAddressText,
        dpid: business.address.pafId
      };

      dispatch('changePhysicalAddress', physicalAddress);
      dispatch('changePhysicalAddressText', physicalAddressText);
      dispatch('changePhysicalAddressLookupResponse', [physicalAddress]);
    } else {
      dispatch('resetPhysicalAddress');
    }
  },
  async businessNameLookup({ commit, dispatch, state }) {
    // Don't bother searching if there's no value
    if (!state.businessName) {
      return;
    }

    try {
      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'businessNameLookup', type: 'request' },
        { root: true }
      );

      const response = await api.searchBusinesses(
        state.businessName,
        state.businessType
      );

      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'businessNameLookup', type: 'success' },
        { root: true }
      );

      commit('changeBusinessLookupResponse', response.data.items);
    } catch (error) {
      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'businessNameLookup', type: 'error' },
        { root: true }
      );

      // If it's a limited company search, show an error toast
      if (state.businessType === LIMITED_COMPANY) {
        dispatch(
          'ui/addErrorToast',
          {
            id: 'failed-business-lookup',
            text:
              'Sorry, we are unable to look up NZ Companies Register this time. Please try again later.'
          },
          { root: true }
        );
      } else {
        // Otherwise, switch to manual input
        dispatch('changeHasManualInputBusiness', true);
      }
    }
  },
  async physicalAddressLookup({ commit, dispatch, state }) {
    // Don't bother searching if there's no value
    if (!state.physicalAddressText) {
      return;
    }

    try {
      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'physicalAddressLookup', type: 'request' },
        { root: true }
      );

      const response = await api.searchAddresses(state.physicalAddressText);

      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'physicalAddressLookup', type: 'success' },
        { root: true }
      );

      commit('changePhysicalAddressLookupResponse', response.data);
    } catch (error) {
      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'physicalAddressLookup', type: 'error' },
        { root: true }
      );

      // Switch to manual input
      dispatch('changeHasManualPhysicalAddress', true);
    }
  }
};

export const getters = {
  isLimitedCompany: state => {
    const entityTypeCode = state.business && state.business.entityTypeCode;
    const isLimitedCompanyBusinessType = state.businessType === LIMITED_COMPANY;

    return isLimitedCompanyBusinessType || entityTypeCode === 'LTD';
  },
  tradingNameWithBusinessNameFallback: state => {
    return state.tradingName ? state.tradingName : state.businessName;
  },
  businessTypeComplete: state => {
    return state.businessType && state.businessUseOnly;
  },
  businessAgeInYears: state => {
    if (!state.business) {
      return null;
    }

    const difference = calculateAge(state.business.registrationDate);

    return difference;
  },
  directors: state => {
    if (!state.business || !state.business.roles) {
      return [];
    }

    return state.business.roles
      .filter(
        role =>
          role.roleType === 'Director' &&
          role.roleStatus === 'ACTIVE' &&
          role.rolePerson
      )
      .map(
        roles => `${roles.rolePerson.firstName} ${roles.rolePerson.lastName}`
      );
  }
};
