import {
  SECTION_YOUR_BUSINESS,
  SECTION_YOUR_OFFER,
  SECTION_CREDIT_CHECK,
  SECTION_FINAL_DETAILS,
  BUSINESS_TYPE,
  BUSINESS_NAME,
  PHYSICAL_ADDRESS,
  FUEL_AMOUNT,
  LOYALTY,
  OFFER_BANNER,
  MEMBERSHIP_NUMBER,
  IS_COMPANY_DIRECTOR,
  LEGAL_NAME,
  GENDER,
  DOB,
  DRIVERS_LICENSE,
  RESIDENTIAL_ADDRESS,
  DELIVERY_ADDRESS,
  CONTACT_NUMBER,
  INVOICE_EMAIL,
  INVOICE_DATE,
  PAYMENT_PLAN,
  BANK_DETAILS,
  SUBMIT_FORM,
} from '@/constants/html-ids';
import { SOLE_TRADER } from '@/constants/form';
import logger from '@/helpers/logging';

export function distanceBetweenPoints(x, y) {
  return Math.abs(x - y);
}

export function elementDistanceFromOffset(element, yOffset) {
  const elementData = element.getBoundingClientRect();
  const { top, bottom } = elementData;

  const topDistance = distanceBetweenPoints(window.pageYOffset + top, yOffset);
  const bottomDistance = distanceBetweenPoints(
    window.pageYOffset + bottom,
    yOffset
  );

  return Math.min(topDistance, bottomDistance);
}

export const state = () => ({
  questionsInView: [],
  sectionsInView: [],
  middleOfPage: 0
});

export const mutations = {
  addSectionInView(state, value) {
    if (!state.sectionsInView.includes(value)) {
      state.sectionsInView = [...state.sectionsInView, value];
    }
  },
  removeSectionInView(state, value) {
    if (state.sectionsInView.includes(value)) {
      state.sectionsInView = state.sectionsInView.filter(
        question => question !== value
      );
    }
  },
  removeQuestionInView(state, value) {
    if (state.questionsInView.includes(value)) {
      state.questionsInView = state.questionsInView.filter(
        question => question !== value
      );
    }
  },
  addQuestionInView(state, value) {
    if (!state.questionsInView.includes(value)) {
      state.questionsInView = [...state.questionsInView, value];
    }
  },
  changeMiddleOfPage(state, value) {
    state.middleOfPage = value;
  }
};

export const actions = {
  addSectionInView({ commit, state }, value) {
    if (!state.sectionsInView.includes(value)) {
      commit('addSectionInView', value);
    }
  },
  removeSectionInView({ commit, state }, value) {
    if (state.sectionsInView.includes(value)) {
      commit('removeSectionInView', value);
    }
  },
  addQuestionInView({ commit, state }, value) {
    if (!state.questionsInView.includes(value)) {
      commit('addQuestionInView', value);
    }
  },
  removeQuestionInView({ commit, state }, value) {
    if (state.questionsInView.includes(value)) {
      commit('removeQuestionInView', value);
    }
  },
  changeMiddleOfPage({ commit }, value) {
    commit('changeMiddleOfPage', value);
  }
};

export const getters = {
  /**
   * Returns the name of the current section
   */
  currentSectionName: (state, getters) => {
    switch (getters.currentSection) {
      case SECTION_YOUR_BUSINESS:
        return 'Your business';
      case SECTION_YOUR_OFFER:
        return 'Your offer';
      case SECTION_CREDIT_CHECK:
        return 'Credit check details';
      case SECTION_FINAL_DETAILS:
        return 'Final details';
      default:
        return '';
    }
  },
  /**
   * Returns the id of the current section.
   * Returns the one closest to the middle of the screen if multiple sections are in view
   */
  currentSection: state => {
    const { sectionsInView, middleOfPage } = state;

    // Find the question that is closest to the middle of the screen
    if (!sectionsInView.length) {
      return null;
    }

    if (sectionsInView.length === 1) {
      return sectionsInView[0];
    }

    let closestSectionId;
    let closestDistance;

    for (const sectionId of sectionsInView) {
      const element = document.getElementById(sectionId);

      if (!element) {
        logger.error(`Element section ${sectionId} not found`);
        continue;
      }

      const elementDistance = elementDistanceFromOffset(element, middleOfPage);

      if (!closestDistance || elementDistance < closestDistance) {
        closestDistance = elementDistance;
        closestSectionId = sectionId;
      }
    }

    return closestSectionId;
  },
  /**
   * Returns the id of the current question
   * Returns the question closest to the middle of the screen if multiple questions are in view
   */
  currentQuestion: state => {
    const { questionsInView, middleOfPage } = state;

    // Find the question that is closest to the middle of the screen
    if (!questionsInView.length) {
      return null;
    }

    if (questionsInView.length === 1) {
      return questionsInView[0];
    }

    let closestQuestionId;
    let closestDistance;

    for (const questionId of questionsInView) {
      const element = document.getElementById(questionId);

      if (!element) {
        logger.error(`Element section ${questionId} not found`);
        continue;
      }

      const elementDistance = elementDistanceFromOffset(element, middleOfPage);
      if (!closestDistance || elementDistance < closestDistance) {
        closestDistance = elementDistance;
        closestQuestionId = questionId;
      }
    }
    return closestQuestionId;
  },
  /**
   * Returns if the current quesiton has a previous question
   */
  hasPreviousQuestion: (state, getters) => {
    if (getters.currentQuestion === BUSINESS_TYPE) {
      return false;
    }

    return true;
  },
  /**
   * Returns if the current quesiton has a next question
   */
  hasNextQuestion: (state, getters) => {
    const allQuestions = getters.allQuestions;
    const currentQuestion = getters.currentQuestion;
    const lastQuestionIndex = allQuestions.length - 1;

    // Find index of current question in the all question array
    const currentQuestionIndex = allQuestions.findIndex(item => {
      return item.value === currentQuestion;
    });

    if (currentQuestionIndex < lastQuestionIndex) {
      const nextQuestion = allQuestions[currentQuestionIndex + 1];

      if (nextQuestion.disabled) {
        return false;
      }

      return true;
    }

    return false;
  },
  /**
   * Returns the previous question for the current question
   */
  previousQuestion: (state, getters) => {
    const allQuestions = getters.allQuestions;
    const currentQuestion = getters.currentQuestion;
    const firstQuestionIndex = 0;

    // Find index of current question in the all question array
    const currentQuestionIndex = allQuestions.findIndex(item => {
      return item.value === currentQuestion;
    });

    if (currentQuestionIndex !== firstQuestionIndex) {
      return allQuestions[currentQuestionIndex - 1];
    }

    // Return the first item
    return allQuestions[firstQuestionIndex];
  },
  /**
   * Returns the next question for the current question
   */
  nextQuestion: (state, getters) => {
    const allQuestions = getters.allQuestions;
    const currentQuestion = getters.currentQuestion;
    const lastQuestionIndex = allQuestions.length - 1;

    // Find index of current question in the all question array
    const currentQuestionIndex = allQuestions.findIndex(item => {
      return item.value === currentQuestion;
    });

    if (currentQuestionIndex !== lastQuestionIndex) {
      return allQuestions[currentQuestionIndex + 1];
    }

    // Return the last item
    return allQuestions[lastQuestionIndex];
  },
  /**
   * Returns all the questions in order
   */
  allQuestions: (state, getters, rootState, rootGetters) => {
    return [
      ...getters.businessQuestions,
      ...getters.offerQuestions,
      ...getters.creditQuestions,
      ...getters.finalDetailsQuestions
    ];
  },
  /**
   * Returns all of the business questions in order
   */
  businessQuestions: (state, getters, rootState) => {
    return [
      {
        value: BUSINESS_TYPE,
        disabled: false
      },
      {
        value: BUSINESS_NAME,
        // If not selected or hasn't selected fuel card use
        disabled:
          !rootState.business.businessType &&          
            !rootState.business.businessUseOnly
      },
      {
        value: PHYSICAL_ADDRESS,
        disabled: !rootState.business.businessNameComplete
      },
      {
        value: FUEL_AMOUNT,
        disabled: !rootState.business.physicalAddressComplete
      }
    ];
  },
  /**
   * Returns all of the offer questions in order
   */
  offerQuestions: (state, getters, rootState, rootGetters) => {
    return [
      {
        value: OFFER_BANNER,
        disabled: false
      },
      {
        value: MEMBERSHIP_NUMBER,
        disabled: !rootState.offer.promoCodeComplete,
        hidden: !rootGetters['offer/membershipNumberRequired']
      },
      {
        value: LOYALTY,
        disabled: !rootState.offer.promoCodeComplete
      }
    ].filter(q => !q.hidden);
  },
  /**
   * Returns all of the credit questions in order
   */
  creditQuestions: (state, getters, rootState, rootGetters) => {
    if (!rootGetters['business/isLimitedCompany']) {
      return [
        {
          value: LEGAL_NAME,
          disabled: false
        },
        {
          value: GENDER,
          disabled: !rootState.credit.legalNameComplete
        },
        {
          value: DOB,
          disabled: !rootState.credit.gender
        },
        {
          value: DRIVERS_LICENSE,
          disabled: !rootState.credit.dateOfBirthComplete
        },
        {
          value: RESIDENTIAL_ADDRESS,
          disabled: !rootState.credit.driversLicenseComplete
        }
      ];
    }

    return [
      {
        value: IS_COMPANY_DIRECTOR,
        disabled: false
      },
      {
        value: LEGAL_NAME,
        disabled:
          !rootState.credit.isCompanyDirector &&
          (rootState.credit.isCompanyDirector === false &&
            !rootState.credit.readRepresentativeRequirements)
      },
      {
        value: GENDER,
        disabled: !rootState.credit.legalNameComplete
      },
      {
        value: DOB,
        disabled: !rootState.credit.gender
      },
      {
        value: DRIVERS_LICENSE,
        disabled: !rootState.credit.dateOfBirthComplete
      },
      {
        value: RESIDENTIAL_ADDRESS,
        disabled: !rootState.credit.driversLicenseComplete
      }
    ];
  },

  /**
   * Returns all of the final details questions in order
   */
  finalDetailsQuestions: (state, getters, rootState, rootGetters) => {
    return [
      {
        value: DELIVERY_ADDRESS,
        disabled: false
      },
      {
        value: CONTACT_NUMBER,
        disabled: !rootState.finalDetails.deliveryAddressComplete
      },
      {
        value: INVOICE_EMAIL,
        disabled: !rootState.finalDetails.contactNumberComplete
      },
      {
        value: INVOICE_DATE,
        disabled: !rootState.finalDetails.invoiceEmailComplete
      },
      {
        value: PAYMENT_PLAN,
        disabled: !rootState.finalDetails.invoiceDateComplete,
        hidden: rootGetters['offer/skipPaymentPlan']
      },
      {
        value: BANK_DETAILS,
        disabled: !rootGetters['finalDetails/paymentPlanComplete']
      },
      {
        value: SUBMIT_FORM,
        disabled: !rootState.finalDetails.bankDetailsComplete
      }
    ].filter(q => !q.hidden);
  },

  /**
   * Returns all of the questions for the current section
   */
  currentQuestions: (state, getters) => {
    switch (getters.currentSection) {
      case SECTION_YOUR_BUSINESS:
        return getters.businessQuestions;
      case SECTION_YOUR_OFFER:
        return getters.offerQuestions;
      case SECTION_CREDIT_CHECK:
        return getters.creditQuestions;
      case SECTION_FINAL_DETAILS:
        return getters.finalDetailsQuestions;
      default:
        break;
    }
  }
};
