import { render, staticRenderFns } from "./form.vue?vue&type=template&id=1849c571&scoped=true&"
import script from "./form.vue?vue&type=script&lang=js&"
export * from "./form.vue?vue&type=script&lang=js&"
import style0 from "./form.vue?vue&type=style&index=0&id=1849c571&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1849c571",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/home/runner/work/business-cards/business-cards/ola/Z.Aspiring.Ola/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VContent } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
installComponents(component, {VApp,VContainer,VContent,VFlex,VLayout})
