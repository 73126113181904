<template>
  <button type="button" class="sidebar-item" @click="onClick">
    <typography
      class="sidebar-item__label"
      type="small-note"
      no-margin
      medium
      >{{ label }}</typography
    >
    <typography no-margin><slot /></typography>
    <typography v-if="note" type="small-p" no-margin>
      {{ note }}
    </typography>
  </button>
</template>

<script>
import Typography from '@/components/atoms/Typography.vue';
import { navigationOffset } from '@/helpers/navigation';

export default {
  components: {
    Typography
  },
  props: {
    toId: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    note: {
      type: String,
      default: ''
    }
  },
  methods: {
    onClick() {
      if (this.toId) {
        this.$vuetify.goTo(`#${this.toId}`, {
          offset: navigationOffset(this.toId)
        });
      }

      if (this.$vuetify.breakpoint.mdAndDown) {
        this.$store.dispatch('ui/toggleDrawer', false);
      }

      this.$emit('click');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.sidebar-item {
  text-align: left;
  outline: 0;
  margin-bottom: rem(15px);
  width: 100%;
}
</style>
