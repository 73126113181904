<template>
  <div class="copy-text">
    <div class="text-box">
      <typography no-margin class="text">
        {{ text }}
      </typography>
    </div>
    <small-tooltip top>
      <icon-button slot="activator" class="copy-button" @click="onCopy">
        <copy-icon />
      </icon-button>
      <typography type="small-p" no-margin>
        <span v-if="!copied">Copy link</span>
        <span v-else>Copied!</span>
      </typography>
    </small-tooltip>
  </div>
</template>

<script>
import IconButton from '@/components/atoms/IconButton.vue';
import CopyIcon from '@/assets/icons/copy.svg';
import Typography from '@/components/atoms/Typography.vue';
import SmallTooltip from '@/components/atoms/SmallTooltip.vue';

export default {
  components: {
    IconButton,
    CopyIcon,
    Typography,
    SmallTooltip
  },
  props: {
    text: {
      type: String,
      default: '',
      required: true
    }
  },
  data() {
    return {
      copied: false
    };
  },
  methods: {
    onCopy() {
      const dummy = document.createElement('textarea');
      document.body.appendChild(dummy);
      dummy.value = this.text;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);

      this.copied = true;

      setTimeout(() => {
        this.copied = false;
      }, 2000);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.copy-text {
  display: flex;
  max-width: 100%;
}

.text-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #eaeaea;
  flex-grow: 1;
  padding: rem(10px 25px);
  text-align: left;
  height: rem(50px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  .text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.copy-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: rem(50px);
  height: rem(50px);
  background-color: $mid-orange;
}
</style>
