// NOTE: CSS order matters!!!
import 'normalize-scss/sass/normalize/_import-now.scss';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'vuetify/src/stylus/app.styl'; // import 'vuetify/dist/vuetify.min.css';
import '@/assets/styles/base.scss';
import { TIMEOUT } from '@/constants/routes';

import '@babel/polyfill'; // Needed for IE
import 'intersection-observer'; // Needed for IE and Edge
import 'scrollingelement'; // Needed for vuetify.goTo method to work in IE < 11

import Vue from 'vue';
import store from './store'; // Must be imported before router
import router from './router';
import { sync } from 'vuex-router-sync';
import App from './App.vue';
import { toUrl } from '@/helpers/url';
import { setClientViewport } from '@/helpers/mobile';
import { IS_PRERENDERING } from '@/helpers/config';

import gtm from './plugins/vue-gtm';
import appInsights from '@/plugins/vue-application-insights';
import './plugins/vuetify';
import './plugins/vuelidate';
import './plugins/vue-observe-visibility';
import './plugins/idle-vue';

sync(store, router);

if (!IS_PRERENDERING) {
  Vue.use(gtm, { router });
  Vue.use(appInsights, { router });
}
Vue.config.devtools = true;
new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    // Don't override the viewport when pre-rendering
    if (!IS_PRERENDERING) {
      setClientViewport();
    }
  },
  /** @description Implementation of Session Timeout when user is inactive for 30 minutes or more. */
  onIdle() {
    if (this.$store.state.saver.applicationId != '') {
      // Redirect to the first page using window.location to ensure
      // state is cleared correctly. Use toUrl method to prefix the
      // base url for the application to the route url.
      window.location.href = toUrl(TIMEOUT);
    }
  }
}).$mount('#app-container');
