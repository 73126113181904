import { render, staticRenderFns } from "./UpdateCardsDialog.vue?vue&type=template&id=b0c24754&scoped=true&"
import script from "./UpdateCardsDialog.vue?vue&type=script&lang=js&"
export * from "./UpdateCardsDialog.vue?vue&type=script&lang=js&"
import style0 from "./UpdateCardsDialog.vue?vue&type=style&index=0&id=b0c24754&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0c24754",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/home/runner/work/business-cards/business-cards/ola/Z.Aspiring.Ola/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
installComponents(component, {VFlex,VLayout})
