<template>
  <modal v-model="removeCardDialogOpen">
    <header class="dialog-header">
      <typography type="h3"
        >Are you sure you want to remove this card?</typography
      >
    </header>

    <round-button
      slot="left-button"
      outline
      @click="onClose"
      name="removeCardDialogNoButton"
    >
      No, keep card
    </round-button>
    <round-button
      slot="right-button"
      @click="onRemoveCard"
      name="removeCardDialogYesButton"
    >
      Yes, remove card
    </round-button>
  </modal>
</template>

<script>
import Modal from '@/components/molecules/Modal.vue';
import Typography from '@/components/atoms/Typography.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';

export default {
  components: {
    Modal,
    Typography,
    RoundButton
  },
  computed: {
    removeCardDialogOpen: {
      get() {
        return this.$store.state.ui.removeCardDialogOpen;
      },
      set(value) {
        this.$store.dispatch('ui/toggleRemoveCardDialogOpen', value);
      }
    }
  },
  methods: {
    onRemoveCard() {
      this.$store.dispatch('cardPreferences/removeCard');
      this.$store.dispatch('ui/toggleRemoveCardDialogOpen', false);
    },
    onClose() {
      this.$store.dispatch('ui/toggleRemoveCardDialogOpen', false);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.dialog-header {
  margin-bottom: rem(30px);
}
</style>
