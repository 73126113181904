export function navigationOffset(elementId) {
  const element = document.getElementById(elementId);

  return navigationOffsetForElement(element);
}

export function navigationOffsetFor(selector) {
  const element = document.querySelector(selector);

  return navigationOffsetForElement(element);
}

function navigationOffsetForElement(element) {
  if (!element) {
    return 0;
  }

  const halfWindowHeight = window.innerHeight / 2;
  const elementData = element.getBoundingClientRect();
  const halfElementHeight = elementData.height / 2;

  return halfWindowHeight - halfElementHeight;
}
