<template>
  <v-navigation-drawer
    v-model="cardPreferencesSidebarOpen"
    class="sidebar"
    fixed
    hide-overlay
    app
    width="555"
  >
    <div class="content">
      <div class="sidebar__inner">
        <header class="sidebar__header">
          <icon-button @click="onHomeClick" name="homeSymbol">
            <home-icon class="home-icon" />
          </icon-button>
          <typography class="num-cards" type="tag">
            You have
            <span class="num-cards__number" name="cardPreferencesCardCount">{{
              numCards
            }}</span>
            {{ brand }} fuel card{{ numCards > 1 ? 's' : '' }}
          </typography>
          <icon-button @click="onAddCard" name="addAnotherCardPlusSymbol">
            <add-icon class="add-icon" />
          </icon-button>
        </header>

        <!-- Sidebar cards -->
        <div class="cards">
          <error-message
            class="error-message"
            full-width
            v-if="
              $v.cardChangesToValidate.$dirty &&
                $v.cardChangesToValidate.$invalid
            "
            >You have incomplete cards</error-message
          >
          <sidebar-card
            v-for="(card, index) in $v.cardChangesToValidate.$each.$iter"
            :key="index"
            :active="activeCardIndex === Number(index)"
            :card-label="getCardLabel(card.$model)"
            :card-summary="getCardSummary(card.$model)"
            :disabled="card.$model.disabled"
            :has-error="card.$dirty && card.$invalid"
            :scale-card-label="getScaleCardLabel(card.$model)"
            @click="onChangeActiveCardIndex(Number(index))"
            @remove="onRemoveCard(Number(index))"
          />
          <!-- Add another card -->
          <card-placeholder
            class="add-another-card"
            text="+ Add another card"
            @click="onAddCard"
            name="addAnotherCard"
          />
        </div>
      </div>

      <!-- V-if on footer for IE11 bug fix -->
      <footer v-if="cardPreferencesSidebarOpen" class="sidebar__footer">
        <v-layout wrap align-center justify-space-between>
          <!-- Help tooltip -->
          <v-flex
            xs12
            sm5
            order-sm2
            mb-3
            mt-3
            d-inline-flex
            justify-end
            align-center
          >
            <tooltip class="help-tooltip" top>
              <div slot="activator" class="help">
                <icon-button>
                  <help-icon />
                </icon-button>
                <typography
                  type="small-p"
                  class="help__text"
                  no-margin
                  name="needHelpLink"
                  >Need help?</typography
                >
              </div>
              <typography
                class="help-tooltip__content"
                type="small-p"
                no-margin
                name="needHelpTooltip"
              >
                We can help tailor your cards if you need a hand — just send
                your card details to
                <a :href="`mailto:${zEmail}`">{{ zEmail }}</a>
                and we’ll sort you out.
              </typography>
            </tooltip>
          </v-flex>
          <!-- Submit button -->
          <v-flex xs12 sm5 order-sm1>
            <round-button
              :disabled="$v.cardChangesToValidate.$invalid"
              color="white"
              @click="onConfirmUpdate"
              name="confirmUpdateButton"
              >Confirm order</round-button
            >
          </v-flex>
        </v-layout>
      </footer>
    </div>
  </v-navigation-drawer>
</template>

<script>
import Typography from '@/components/atoms/Typography.vue';
import Tooltip from '@/components/atoms/Tooltip.vue';
import IconButton from '@/components/atoms/IconButton.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';
import SidebarCard from '@/components/molecules/SidebarCard.vue';
import CardPlaceholder from '@/components/molecules/CardPlaceholder.vue';
import HelpIcon from '@/assets/icons/help-white.svg';
import HomeIcon from '@/assets/icons/home.svg';
import AddIcon from '@/assets/icons/add.svg';
import ErrorMessage from '@/components/molecules/ErrorMessage.vue';
import { HOME, Z_EMAIL } from '@/constants/routes';
import { mapState, mapGetters } from 'vuex';
import {
  CARD_TYPE_ANY,
  CARD_TYPE_VEHICLE,
  CARD_TYPE_DRIVER,
  CARD_TYPE_DRIVER_VEHICLE,
  TOO_MANY_NEW_CARDS_THRESHOLD,
  BRAND
} from '@/constants/form';
import { requiredIf, minValue, alphaNum } from 'vuelidate/lib/validators';
import { onlyDigits } from '@/validators';

export default {
  components: {
    Typography,
    Tooltip,
    IconButton,
    RoundButton,
    CardPlaceholder,
    ErrorMessage,
    SidebarCard,
    HelpIcon,
    HomeIcon,
    AddIcon
  },
  data() {
    return {
      zEmail: Z_EMAIL,
      brand: BRAND
    };
  },
  computed: {
    cardPreferencesSidebarOpen: {
      get() {
        if (this.$vuetify.breakpoint.lgAndUp) {
          return true;
        }

        return this.$store.state.ui.cardPreferencesSidebarOpen;
      },
      set(isOpen) {
        this.$store.dispatch('ui/toggleCardPreferencesSidebarOpen', isOpen);
      }
    },
    /**
     * Add card type + disabled to card properties for vuelidate nestedModel
     */
    cardChangesToValidate() {
      return this.cardChanges.map(card => {
        return {
          ...card,
          vehicle: {
            ...card.vehicle,
            disabled: card.disabled,
            cardType: card.cardType
          },
          driver: {
            ...card.driver,
            disabled: card.disabled,
            cardType: card.cardType
          },
          limits: {
            ...card.limits,
            disabled: card.disabled,
            companyMonthlySpending: this.companyMonthlySpending
          }
        };
      });
    },
    ...mapState({
      cardChanges: state => state.cardPreferences.cardChanges,
      activeCardIndex: state => state.cardPreferences.activeCardIndex,
      companyMonthlySpending: state => state.business.fuelAmount
    }),
    ...mapGetters({
      numCards: 'cardPreferences/numCards'
    })
  },
  watch: {
    cardPreferencesSidebarOpen(newVal, oldVal) {
      // Touch all fields when opening the sidebar on mobile to show invalid cards.
      if (this.$vuetify.breakpoint.xsOnly && newVal !== oldVal) {
        if (newVal) {
          this.$v.cardChangesToValidate.$touch();
        } else {
          this.$v.cardChangesToValidate.$reset();
        }

        for (const card of Object.values(
          this.$v.cardChangesToValidate.$each.$iter
        )) {
          if (newVal) {
            card.$touch();
          } else {
            card.$reset();
          }
        }
      }
    },
    activeCardIndex(newVal, oldVal) {
      // Close the drawer after adding a card on non desktop devices
      if (this.$vuetify.breakpoint.mdAndDown && newVal > oldVal) {
        this.$store.dispatch('ui/toggleCardPreferencesSidebarOpen', false);
      }

      // Touch the old card
      if (this.$v.cardChangesToValidate.$each[oldVal]) {
        this.$v.cardChangesToValidate.$each[oldVal].$touch();
      }

      // Reset the new selected card
      if (this.$v.cardChangesToValidate.$each[newVal]) {
        this.$v.cardChangesToValidate.$each[newVal].$reset();
      }

      // Check if any of the non selected cards are invalid + dirty
      const numErrors = Object.values(
        this.$v.cardChangesToValidate.$each.$iter
      ).reduce((acc, card) => {
        if (card.$dirty && card.$invalid) {
          return acc + 1;
        }

        return acc;
      }, 0);

      // Trigger the grouped error message if there are any that aren't selected
      if (numErrors > 0) {
        this.$v.cardChangesToValidate.$touch();
      } else {
        this.$v.cardChangesToValidate.$reset();
      }
    }
  },
  methods: {
    onHomeClick() {
      window.location.href = HOME;
    },
    onAddCard() {
      if (this.numCards === TOO_MANY_NEW_CARDS_THRESHOLD) {
        this.$store.dispatch('ui/toggleTooManyCardsDialogOpen', true);
      } else {
        this.$store.dispatch('cardPreferences/addCard');
      }
    },
    onConfirmUpdate() {
      this.$v.cardChangesToValidate.$touch();

      if (!this.$v.cardChangesToValidate.$invalid) {
        this.$store.dispatch('ui/toggleUpdateCardsDialogOpen', true);
      }
    },
    onRemoveCard(index) {
      this.$store.dispatch('cardPreferences/changeCardIndexToRemove', index);
      this.$store.dispatch('ui/toggleRemoveCardDialogOpen', true);
    },
    onChangeActiveCardIndex(index) {
      this.$store.dispatch('cardPreferences/changeActiveCardIndex', index);

      if (this.$vuetify.breakpoint.mdAndDown) {
        this.$store.dispatch('ui/toggleCardPreferencesSidebarOpen', false);
      }
    },
    getCardLabel(card) {
      switch (card.cardType) {
        case CARD_TYPE_ANY:
          return 'Any';
        case CARD_TYPE_VEHICLE:
          return 'Vehicle';
        case CARD_TYPE_DRIVER:
          return 'Driver';
        case CARD_TYPE_DRIVER_VEHICLE:
          return 'Driver & Vehicle';
        default:
          return '';
      }
    },
    getCardSummary(card) {
      switch (card.cardType) {
        case CARD_TYPE_ANY:
          return 'Any driver, any vehicle';
        case CARD_TYPE_VEHICLE:
          return card.vehicle.registration;
        case CARD_TYPE_DRIVER:
          return card.driver.name;
        case CARD_TYPE_DRIVER_VEHICLE:
          return `${card.driver.name} ${card.vehicle.registration}`;
        default:
          return '';
      }
    },
    getScaleCardLabel(card) {
      return card.cardType === CARD_TYPE_DRIVER_VEHICLE;
    }
  },
  validations() {
    return {
      cardChangesToValidate: {
        $each: {
          driver: {
            name: {
              required: requiredIf(
                nestedModel =>
                  !nestedModel.disabled &&
                  (nestedModel.cardType === CARD_TYPE_DRIVER ||
                    nestedModel.cardType === CARD_TYPE_DRIVER_VEHICLE)
              )
            }
          },
          vehicle: {
            registration: {
              required: requiredIf(
                nestedModel =>
                  !nestedModel.disabled &&
                  (nestedModel.cardType === CARD_TYPE_VEHICLE ||
                    nestedModel.cardType === CARD_TYPE_DRIVER_VEHICLE)
              ),
              alphaNum
            }
          },
          limits: {
            // these need to be duplicated in card-preferences/index.vue
            daily: {
              onlyDigits,
              minValue: minValue(1),
              lessThanMonthlyLimit: function(dailyValue, values) {
                if (!values.monthly || !dailyValue) {
                  return true;
                }
                return Number(dailyValue) <= Number(values.monthly);
              }
            },
            monthly: {
              onlyDigits,
              minValue: minValue(1),
              lessThanCompanyMonthlySpending: function(monthlyValue, values) {
                return (
                  Number(monthlyValue) <= Number(values.companyMonthlySpending)
                );
              }
            }
          }
        }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.sidebar {
  color: $white;
  max-width: 100%;
  background-image: linear-gradient(-161deg, #f7941e 3%, #f76b1c 100%);

  &__header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba($white, 0.1);
    padding: rem(25px 0 25px 0);
    margin: rem(0 40px);
  }

  &__inner {
    height: calc(100vh - #{rem(120px)});
    overflow-y: auto;
  }

  &__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    color: $orange;
    padding: rem(20px 40px);

    @include md {
      padding: rem(35px 80px);
    }
  }

  .num-cards {
    margin: 0 auto;

    @include sm {
      margin: rem(0 auto 0 40px);
    }

    &__number {
      color: $dark-blue;
      font-weight: $weight-bold;
    }
  }
}

.add-another-card {
  margin: rem(30px auto);

  @include sm {
    margin: rem(30px 0 30px 45px);
  }
}

.content {
  position: relative;
  width: 100%;
  max-height: 100vh;
  overflow-y: hidden;

  .help {
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    &__text {
      margin-left: rem(10px);
    }
  }
}

.home-icon {
  /deep/ path {
    fill: $white;
  }
}

.add-icon {
  width: rem(20px);
  height: rem(20px);

  /deep/ g {
    stroke: $white;
  }
}

.cards {
  padding: rem(0 20px);
  text-align: center;

  @include sm {
    text-align: left;
    padding: rem(20px 0 40px 50px);
  }
}

.help-tooltip {
  color: $white;

  @include sm {
    order: 2;
    flex-grow: 0 !important;
  }

  &__content {
    width: rem(270px);
    color: $charcoal;
  }
}

.error-message {
  margin-top: rem(10px);
  margin-bottom: rem(10px);

  @include sm {
    margin-bottom: rem(25px);
    margin-right: rem(50px);
  }
}
.sidebar__footer {

    // need to override `position: fixed;` to fix bug in IE
    @include sm {
      position: relative !important;
    }
}
</style>
