<template>
  <v-checkbox
    :label="label"
    :name="name"
    :value="value"
    :input-value="inputValue"
    :class="['checkbox']"
    @change="onChange"
  >
    <slot slot="label" name="label" />
  </v-checkbox>
</template>

<script>
export default {
  model: {
    prop: 'inputValue',
    event: 'change'
  },
  props: {
    label: {
      type: String,
      default: undefined
    },
    name: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: undefined
    },
    inputValue: {
      type: null,
      default: undefined
    }
  },
  methods: {
    onChange(value) {
      this.$emit('change', value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.checkbox {
  margin: 0;

  /deep/ .v-label {
    margin-top: rem(4px);
    color: $charcoal;
    font-size: rem(12px);
    line-height: rem(16px);

    @include md {
      margin-top: rem(2px);
      font-size: rem(15px);
    }
  }

  /deep/ .v-icon {
    color: $mid-charcoal;

    &.accent--text {
      color: $bach-blue;
    }
  }

  /deep/ .v-input {
    &__slot {
      margin-bottom: 0;
      align-items: flex-start;
    }
  }

  /deep/ .v-messages {
    min-height: 0;
  }
}
</style>
