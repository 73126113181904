import api from '@/api';
import { CARD_TYPE_ANY } from '@/constants/form';
import { deepEqual } from 'fast-equals';
import { SAVE_CARDS_ERROR } from '@/constants/errors';

export const PRODUCTS_OPTIONS = [
  { value: 'AllExcludingTobacco', label: 'All Products excluding Tobacco' },
  {
    value: 'AllExcludingTobaccoAndAdBlue',
    label: 'All Products excluding Tobacco & AdBlue'
  },
  {
    value: 'DieselOilWashAndService',
    label: 'Diesel, Oil, Wash & Service'
  },
  { value: 'FuelsAndAdBlue', label: 'Fuels & AdBlue' },
  { value: 'FuelsOilWashService', label: 'Fuels, Oil, Wash & Service' },
  { value: 'FuelsOnly', label: 'Fuels Only' },
  { value: 'PetrolOilAndWash', label: 'Petrol, Oil & Wash' }
];

export const DEFAULT_CARD = {
  cardType: CARD_TYPE_ANY,
  vehicle: {
    registration: ''
  },
  driver: {
    name: ''
  },
  products: 'AllExcludingTobacco',
  limits: {
    daily: 250,
    monthly: null
  }
};

/**
 * 'cards' field is the pre existing cards from the server that the user has already ordered/owns
 * All pending changes should be made 'cardChanges' field.
 * This is so 'discard changes' action can be done
 * Also so we can calculate the cost of any changes/new cards/removed cards
 */
export const state = () => ({
  cardChanges: [DEFAULT_CARD],
  activeCardIndex: 0,
  cardIndexToRemove: -1,
  companyMonthlySpendingLimit: undefined,
  sectionComplete: false
});

export const mutations = {
  removeCard(state) {
    state.cardChanges = state.cardChanges.filter(
      (card, cardIndex) => cardIndex !== state.cardIndexToRemove
    );
  },
  addCard(state) {
    state.cardChanges = [...state.cardChanges, DEFAULT_CARD];
  },
  changeCardIndexToRemove(state, index) {
    state.cardIndexToRemove = index;
  },
  /**
   * Discards all changes to the active card
   */
  discardActiveCardChanges(state) {
    state.cardChanges = state.cardChanges.map((card, cardIndex) => {
      if (cardIndex === state.activeCardIndex) {
        // Else just set it to the default card
        return {
          ...DEFAULT_CARD
        };
      }

      return card;
    });
  },
  /**
   * Applies the active card settings to all other cards
   */
  applyActiveCardToAllCards(state) {
    const activeCard = state.cardChanges[state.activeCardIndex];

    state.cardChanges = state.cardChanges.map((card, cardIndex) => {
      if (cardIndex !== state.activeCardIndex) {
        return {
          ...activeCard
        };
      }

      return card;
    });
  },
  changeActiveCardIndex(state, index) {
    state.activeCardIndex = index;
  },
  changeCardType(state, value) {
    state.cardChanges = state.cardChanges.map((card, index) => {
      if (index === state.activeCardIndex) {
        return {
          ...card,
          cardType: value
        };
      }

      return card;
    });
  },
  changeVehicle(state, { key, value }) {
    state.cardChanges = state.cardChanges.map((card, index) => {
      if (index === state.activeCardIndex) {
        return {
          ...card,
          vehicle: {
            ...card.vehicle,
            [key]: value
          }
        };
      }

      return card;
    });
  },
  changeDriverName(state, value) {
    state.cardChanges = state.cardChanges.map((card, index) => {
      if (index === state.activeCardIndex) {
        return {
          ...card,
          driver: {
            ...card.driver,
            name: value
          }
        };
      }

      return card;
    });
  },
  changeDailyLimit(state, value) {
    state.cardChanges = state.cardChanges.map((card, index) => {
      if (index === state.activeCardIndex) {
        return {
          ...card,
          limits: {
            ...card.limits,
            daily: value
          }
        };
      }

      return card;
    });
  },
  changeMonthlyLimit(state, value) {
    state.cardChanges = state.cardChanges.map((card, index) => {
      if (index === state.activeCardIndex) {
        return {
          ...card,
          limits: {
            ...card.limits,
            monthly: value
          }
        };
      }

      return card;
    });
  },
  changeProduct(state, value) {
    state.cardChanges = state.cardChanges.map((card, index) => {
      if (index === state.activeCardIndex) {
        // 'all' either toggles everything on or off

        return {
          ...card,
          products: value
        };
      }

      return card;
    });
  },
  changeSectionComplete(state, value) {
    state.sectionComplete = value;
  }
};

export const actions = {
  resetCardChanges({ commit }) {
    commit('resetCardChanges');
  },
  removeCard({ commit, dispatch, state }) {
    const { cardIndexToRemove, activeCardIndex, cardChanges } = state;

    // Else it is an unordered card, just remove it
    if (cardChanges.length === 1) {
      commit('changeActiveCardIndex', null);
    } else if (cardIndexToRemove <= activeCardIndex) {
      commit('changeActiveCardIndex', activeCardIndex - 1);
    }

    commit('removeCard');

    dispatch('changeCardIndexToRemove', -1);
  },
  changeCardIndexToRemove({ commit }, index) {
    commit('changeCardIndexToRemove', index);
  },
  addCard({ commit, state }) {
    commit('addCard');
    commit('changeActiveCardIndex', state.cardChanges.length - 1);
  },
  changeActiveCardIndex({ commit }, index) {
    commit('changeActiveCardIndex', index);
  },
  discardActiveCardChanges({ commit }) {
    commit('discardActiveCardChanges');
  },
  applyActiveCardToAllCards({ commit }) {
    commit('applyActiveCardToAllCards');
  },
  changeCards({ commit }, value) {
    commit('changeCards', value);
  },
  changeCardType({ commit }, value) {
    commit('changeCardType', value);
  },
  changeVehicle({ commit }, { key, value }) {
    commit('changeVehicle', { key, value });
  },
  changeDriverName({ commit }, value) {
    commit('changeDriverName', value);
  },
  changeDailyLimit({ commit }, value) {
    commit('changeDailyLimit', value);
  },
  changeMonthlyLimit({ commit }, value) {
    commit('changeMonthlyLimit', value);
  },
  changeProduct({ commit }, value) {
    commit('changeProduct', value);
  },
  async submitCardChanges({ commit, rootState, dispatch, state }) {
    try {
      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'submitCardChanges', type: 'request' },
        { root: true }
      );

      await api.submitCardPreferences(
        rootState.saver.applicationId,
        state.cardChanges
      );

      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'submitCardChanges', type: 'success' },
        { root: true }
      );

      dispatch('ui/toggleUpdateCardsDialogOpen', false, { root: true });
      commit('changeSectionComplete', true);
    } catch (error) {
      // Update progress
      commit(
        'progress/changeProgress',
        { key: 'submitCardChanges', type: 'error' },
        { root: true }
      );

      dispatch(
        'ui/addErrorToast',
        {
          id: 'failed-update-cards',
          text: SAVE_CARDS_ERROR
        },
        { root: true }
      );
    }
  }
};

export const getters = {
  numCards: state => {
    return state.cardChanges.length;
  },
  activeCard: state => {
    const { activeCardIndex, cardChanges } = state;

    if (activeCardIndex >= 0 && activeCardIndex < cardChanges.length) {
      return cardChanges[activeCardIndex];
    }

    return null;
  },
  activeCardHasChanged: state => {
    const { activeCardIndex, cardChanges } = state;

    const activeCard = {
      ...cardChanges[activeCardIndex]
    };
    const defaultCard = {
      ...DEFAULT_CARD
    };

    // Card has changed if it is different from DEFAULT_CARD
    if (!deepEqual(defaultCard, activeCard)) {
      return true;
    }

    return false;
  }
};
