import VueAppInsights from 'vue-application-insights';
import logger from '@/helpers/logging';

const plugin = {
  install(vue, options) {
    // check if we're using appInsights for logging before adding the plugin.
    if (logger.appInsights) {
      vue.use(VueAppInsights, {
        router: options.router,
        appInsights: logger.appInsights,
        trackInitialPageView: true
      });
    }

    /**
     * trackException - Logs an exception you have caught.
     * @param   exception   An Error from a catch clause, or the string error message.
     * @param   handledAt   Not used
     * @param   properties  map[string, string] - additional data used to filter events and metrics in the portal. Defaults to empty.
     * @param   measurements    map[string, number] - metrics associated with this event, displayed in Metrics Explorer on the portal. Defaults to empty.
     * @param   severityLevel   AI.SeverityLevel - severity level
     */

    // Global error handler for vue errors
    vue.config.errorHandler = function(err) {
      // `info` is a Vue-specific error info, e.g. which lifecycle hook the error was found in.
      logger.error(err);
    };

    // Error handler for any remaining uncaught errors
    window.onerror = (message, source, lineno, colno, err) => {
      logger.error(err);
    };
  }
};

export default plugin;
