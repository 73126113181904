<template>
  <div :class="['error-message', { 'error-message--full-width': fullWidth }]">
    <error-icon class="error-icon" />
    <typography type="small-p" no-margin>
      <slot />
    </typography>
  </div>
</template>

<script>
import Typography from '@/components/atoms/Typography.vue';
import ErrorIcon from '@/assets/icons/error.svg';

export default {
  components: {
    Typography,
    ErrorIcon
  },
  props: {
    fullWidth: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.error-message {
  display: inline-flex;
  align-items: center;
  background-color: $error-color;
  border-radius: rem(4px);
  padding: rem(4px 8px);

  &--full-width {
    display: flex;
  }
}

.error-icon {
  margin-right: rem(10px);
}
</style>
