<template>
  <v-expansion-panel :value="value" class="expansion-panel" @input="onInput">
    <slot />
  </v-expansion-panel>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: null
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.expansion-panel {
  box-shadow: none;
  color: inherit;
}
</style>
