export const LANDING                    = '/';
export const REQUIREMENTS               = '/requirements';
export const FORM                       = '/form';
export const SPECIAL_RATES              = '/form/special-rates';
export const CALLBACK_REQUESTED         = '/form/callback-requested';
export const CREDIT_CHECK_FAILED        = '/form/credit-check-failed';
export const NEXT_STEPS                 = '/form/next-steps';
export const CARD_PREFERENCES           = '/card-preferences';
export const TIMEOUT                    = '/timeout';
export const CARD_PREFERENCES_COMPLETED = '/completed';

export const DIRECT_DEBIT_TERMS_LINK    = 'https://www.z.co.nz/for-businesses/z-business-fuel-card/terms-and-conditions/direct-debit/';
export const PRIVACY_POLICY_LINK        = 'https://z.co.nz/privacy/';
export const Z_TERMS_LINK               = 'https://www.z.co.nz/for-businesses/z-business-fuel-card/terms-and-conditions/';
export const HOME                       = 'https://z.co.nz/business';
export const Z_EMAIL                    = 'zbusiness@z.co.nz';
export const Z_CONSUMER_CARD_LINK       = 'https://z.co.nz/motorists';
