<template>
  <modal
    v-model="personalGuaranteeDialog"
    :persistent="false"
    :preventClose="false"
  >
    <!-- PG Header -->
    <v-layout justify-center mb-4>
      <v-flex sm10>
        <typography type="h3">Personal Guarantee</typography>
        <typography
          :type="$vuetify.breakpoint.xsOnly ? 'small-p' : 'body'"
          light
        >
          A credit check has been completed, and the result falls outside of our
          usual credit criteria. To continue your {{ brand }} journey with us,
          we will need a personal guarantee.
        </typography>
      </v-flex>
    </v-layout>

    <!-- Pg details -->
    <div class="content">
      <v-layout wrap justify-center mb-4>
        <!-- Left column -->
        <v-flex :pr-4="$vuetify.breakpoint.mdAndUp" xs12 sm10 md6 mb-3>
          <typography type="small-p" bold>What does this mean?</typography>
          <typography type="small-p">
            You’ll be a personal guarantor for this account. This means if the
            business is unable to pay you’ll be liable for payment of any
            outstanding invoices.
          </typography>
          <typography type="small-p">
            If you need to add someone else to be a personal guarantor, please
            contact us on 0800 474 355.
          </typography>
        </v-flex>
        <!-- Right column -->
        <v-flex :pl-4="$vuetify.breakpoint.mdAndUp" xs12 sm10 md6>
          <typography type="small-p" bold>
            <strong>Personal Guarantee</strong>
          </typography>
          <typography type="small-p">
            I guarantee to Z Energy the due and punctual payment of all
            obligations that are now and/or that may in the future be due and
            payable or required to be performed by {{ legalName }} to Z Energy
            and I will be liable to Z Energy for any such amounts.
          </typography>
        </v-flex>
      </v-layout>

      <!-- PG checkbox and email -->
      <v-layout justify-center>
        <v-flex sm10>
          <typography type="small-p" no-margin
            >A copy of the Personal Guarantee will be emailed to you
            at</typography
          >
        </v-flex>
      </v-layout>
      <v-layout justify-center>
        <v-flex sm10>
          <text-input
            v-model="personalGuaranteeEmail"
            :rules="[
              () => $v.personalGuaranteeEmail.email || 'Email must be valid'
            ]"
            no-margin
            placeholder="Email address"
            @blur="$v.personalGuaranteeEmail.$touch()"
          />
        </v-flex>
      </v-layout>
      <v-layout justify-center>
        <v-flex sm11>
          <box no-margin>
            <checkbox
              v-model="agreedToPersonalGuarantee"
              :label="
                `${legalName} agrees to the Personal Guarantee in favour of Z Energy.`
              "
            />
          </box>
        </v-flex>
      </v-layout>
    </div>

    <round-button
      slot="right-button"
      :loading="submitPersonalGuaranteeInProgress"
      :disabled="$v.$invalid"
      @click="onContinueClick"
      >Continue</round-button
    >
  </modal>
</template>

<script>
import Modal from '@/components/molecules/Modal.vue';
import Typography from '@/components/atoms/Typography.vue';
import TextInput from '@/components/atoms/TextInput.vue';
import Box from '@/components/atoms/Box.vue';
import Checkbox from '@/components/atoms/Checkbox.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';
import { NEXT_STEPS } from '@/constants/routes';
import { mapState, mapGetters } from 'vuex';
import { email } from '@/validators';

export default {
  components: {
    Modal,
    Typography,
    TextInput,
    RoundButton,
    Box,
    Checkbox
  },
  computed: {
    personalGuaranteeDialog: {
      get() {
        return this.$store.state.ui.personalGuaranteeDialogOpen;
      },
      set(value) {
        this.$store.dispatch('ui/togglePersonalGuaranteeDialog', value);
      }
    },
    personalGuaranteeEmail: {
      get() {
        return this.$store.state.credit.personalGuaranteeEmail;
      },
      set(value) {
        this.$store.dispatch('credit/changePersonalGuaranteeEmail', value);
      }
    },
    agreedToPersonalGuarantee: {
      get() {
        return this.$store.state.credit.agreedToPersonalGuarantee;
      },
      set(value) {
        this.$store.dispatch('credit/changeAgreedToPersonalGuarantee', value);
      }
    },
    ...mapState({
      legalName: state => state.credit.legalName,
      submitPersonalGuaranteeInProgress: state =>
        state.progress.submitPersonalGuarantee.inProgress
    }),
    ...mapGetters({
      brand: 'saver/brand'
    })
  },
  methods: {
    async onContinueClick() {
      await this.$store.dispatch('credit/submitPersonalGuarantee');
      this.$store.dispatch('ui/togglePersonalGuaranteeDialog', false);

      await this.$nextTick();

      this.$router.push(NEXT_STEPS);
    }
  },
  validations: {
    personalGuaranteeEmail: {
      email
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.content {
  text-align: left;

  @include sm {
    padding: rem(0 52px);
  }

  @include md {
    padding: 0;
  }
}

.pg-inputs {
  margin-top: rem(40px);
}
</style>
