export const state = () => ({
  firstName: '',
  lastName: '',
  name: '',
  email: '',
  acceptedUpdates: true,
  sectionComplete: false
});

export const mutations = {
  setFirstName(state, value) {
    state.firstName = value;
    state.name = `${state.firstName} ${state.lastName}`;
  },
  setLastName(state, value) {
    state.lastName = value;
    state.name = `${state.firstName} ${state.lastName}`;
  },
  setName(state, value)
  {
    state.name = value;
  },
  setEmail(state, value) {
    state.email = value;
  },
  setAcceptedUpdates(state, value) {
    state.acceptedUpdates = value;
  }
};

export const actions = {
  setFirstName({ commit }, value) {
    commit('setFirstName', value);
  },
  setLastName({ commit }, value) {
    commit('setLastName', value);
  },
  setName({ commit }, value) {
    commit('setName', value);
  },
  setEmail({ commit }, value) {
    commit('setEmail', value);
  },
  setAcceptedUpdates({ commit }, value) {
    commit('setAcceptedUpdates', value);
  }
};

export const getters = {
  firstName: state => {
    const name = state.name;

    if (!name.length) {
      return name;
    }

    const parts = name.split(' ');

    return parts[0];
  }
};
