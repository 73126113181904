<template>
  <div id="app-container">
    <loading-bar></loading-bar>
    <transition name="layout" mode="out-in">
      <component :is="layout"></component>
    </transition>
  </div>
</template>

<script>
import LoadingBar from '@/components/atoms/LoadingBar';
import DefaultLayout from '@/layouts/default';
import FormLayout from '@/layouts/form';
import CardPreferencesLayout from '@/layouts/card-preferences';

export default {
  components: {
    DefaultLayout,
    FormLayout,
    CardPreferencesLayout,
    LoadingBar
  },
  computed: {
    layout() {
      if (this.$route.meta.layout) {
        return `${this.$route.meta.layout}-layout`;
      }

      return 'default-layout';
    }
  }
};
</script>
