<template>
  <div class="card-placeholder" @click="onClick">
    <typography class="card-placeholder__text" bold no-margin>
      {{ text }}
    </typography>
  </div>
</template>

<script>
import Typography from '@/components/atoms/Typography.vue';

export default {
  components: {
    Typography
  },
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  methods: {
    onClick() {
      this.$emit('click');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.card-placeholder {
  cursor: pointer;
  background: #f26522;
  border-radius: rem(14px);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: rem(200px);
  padding: rem(50px 10px);

  &__text {
  }
}
</style>
