import {
  isValid,
  differenceInCalendarYears,
  startOfDay,
  subYears,
  format
} from 'date-fns/esm';

/**
 * Takes a date and converts it to a string. Allow for strings to cater for dates coming back from the API.
 * @param {string} val The date to format
 * @param {string?} stringFormat The format of the result string (default is dd/MM/yyyy)
 * @returns {string} The formatted string in form of the specified locale
 */
export function formatDateTime(val, stringFormat = 'dd/MM/yyyy') {
  if (val) {
    return format(val, stringFormat);
  }

  return val;
}

/**
 * Takes a date and string and converts it to another string format. NB this function is needed for
 * formatting of the date selected by DatePicker, which is in the format yyyymmdd
 * @param {string} val The date to format
 * @param {string?} toFormat The format of the result string (default is dd/MM/yyyy)
 * @param {string?} fromFormat The format of the string being passed in (default is yyyy-MM-dd)
 * @returns {string} The formatted string in form of the specified locale
 */
export function formatDate(
  val,
  toFormat = 'dd/MM/yyyy',
  fromFormat = 'yyyy-MM-dd'
) {
  // Check the date is complete
  if (val && val.length === fromFormat.length) {
    const year = val.substr(fromFormat.indexOf('yyyy'), 4);
    const month = val.substr(fromFormat.indexOf('MM'), 2);
    const day = val.substr(fromFormat.indexOf('dd'), 2);

    return toFormat
      .replace('yyyy', year)
      .replace('MM', month)
      .replace('dd', day);
  }

  return null;
}

/**
 * Takes a date string and returns an age in years
 * @param {Date|String} val The date or string value
 * @returns {number} The age calculated from the date
 */
export function calculateAge(val) {
  if (isValid(val)) {
    return differenceInCalendarYears(new Date(), val);
  }

  return null;
}

/**
 * Takes an age in years and returns a date.
 * @param {number} age The age used to find the date.
 * @returns {String} The date calculated from the supplied age.
 */
export function getDateOfBirth(age) {
  return format(subYears(startOfDay(new Date()), age), 'yyyy-MM-dd');
}
