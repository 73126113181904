import { req, regex } from 'vuelidate/lib/validators/common';

export default value => {
  // Check if a value has been passed in.
  if (!req(value)) {
    return true;
  }

  // Check if the number starts or ends with a delimeter, or multiple
  // delimeters back to back.
  if (value.match(/^-|^\s|--|\s\s|-$|\s$/g)) {
    return false;
  }

  // Strip out delimeters, i.e. spaces and '-'
  var phoneNumber = value.replace(/\s|-/g, '');
  /* eslint-disable no-useless-escape */
  return regex(
    'phone',
    // Can start with '+####', '(+####)', '+(####)', or '####'.
    // Second part can contain '(###)', or '###'.
    // The phone number should finish with another 5 - 7 digits.
    /^(\+?\d{2,4}|\(\+?\d{2,4}\)|\+?\(\d{2,4}\))(\(\d{1,3}\)|\d{1,3})\d{5,7}$/
  )(phoneNumber);
};
