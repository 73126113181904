import Vue from 'vue';
import IdleVue from 'idle-vue';
import store from '@/store/';
import { TIMEOUT_MINS } from '@/helpers/config';

const eventsHub = new Vue();
const timeoutMilliseconds = Number(TIMEOUT_MINS) * 60000;

Vue.use(IdleVue, {
  store,
  eventEmitter: eventsHub,
  idleTime: timeoutMilliseconds
});
