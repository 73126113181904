<template>
  <div class="price-row">
    <typography light class="price-row__label" no-margin>
      {{ label }}
    </typography>
    <typography light class="price-row__quantity" no-margin>
      {{ quantity }}
    </typography>
  </div>
</template>

<script>
import Typography from '@/components/atoms/Typography.vue';
import { formatCurrency } from '@/helpers/numeral';

export default {
  components: {
    Typography
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    quantity: {
      type: Number,
      default: 0
    },
    amount: {
      type: Number,
      default: 0
    }
  },
  computed: {
    formattedAmount() {
      return formatCurrency(this.amount);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.price-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
  padding: rem(10px 0);
  text-align: left;

  &__label {
    min-width: rem(220px);
  }

  &__quantity {
    flex-grow: 1;
  }

  &__currency {
    &--disabled {
      color: #d2d2d2;
    }
  }
}
</style>
