<template>
  <modal v-model="discardActiveCardChangesDialogOpen">
    <header class="dialog-header">
      <v-layout justify-center>
        <v-flex sm9>
          <typography type="h3"
            >Are you sure you want to discard changes?</typography
          >
        </v-flex>
      </v-layout>
      <v-layout justify-center>
        <v-flex sm7>
          <typography light>
            This will discard any charges to the current card.
          </typography>
        </v-flex>
      </v-layout>
    </header>

    <round-button
      slot="left-button"
      outline
      @click="onCancel"
      name="discardChangesDialogCancel"
    >
      Cancel
    </round-button>
    <round-button
      slot="right-button"
      @click="onDiscardChanges"
      name="discardChangesDialogContinue"
    >
      Yes, discard card changes
    </round-button>
  </modal>
</template>

<script>
import Modal from '@/components/molecules/Modal.vue';
import Typography from '@/components/atoms/Typography.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';

export default {
  components: {
    Modal,
    Typography,
    RoundButton
  },
  computed: {
    discardActiveCardChangesDialogOpen: {
      get() {
        return this.$store.state.ui.discardActiveCardChangesDialogOpen;
      },
      set(value) {
        this.$store.dispatch(
          'ui/toggleDiscardActiveCardChangesDialogOpen',
          value
        );
      }
    }
  },
  methods: {
    onDiscardChanges() {
      this.$store.dispatch('cardPreferences/discardActiveCardChanges');
      this.$store.dispatch(
        'ui/toggleDiscardActiveCardChangesDialogOpen',
        false
      );
    },
    onCancel() {
      this.$store.dispatch(
        'ui/toggleDiscardActiveCardChangesDialogOpen',
        false
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.dialog-header {
  margin-bottom: rem(30px);
}
</style>
