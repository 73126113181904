<template>
  <v-app class="app" v-show="saverId">
    <form-header/>
    <v-content class="content">
      <div class="left-content">
        <v-container class="left-container">
          <v-layout>
            <v-flex xs12>
              <router-view></router-view>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </v-content>
    <form-summary-sidebar/>
    <save-and-share-dialog/>
    <personal-guarantee-dialog/>
    <personal-card-dialog/>
    <mobile-form-menu/>
    <carousel-navigation
      :items="currentQuestions"
      :value="currentQuestion"
      :previous-arrow-disabled="!hasPreviousQuestion"
      :next-arrow-disabled="!hasNextQuestion"
      :class="[
        'carousel-navigation',
        { 'carousel-navigation--drawer-open': drawerOpen }
      ]"
      @click="onNavigationClick"
      @prev-click="onPreviousQuestionClick"
      @next-click="onNextQuestionClick"
    />
    <icon-button
      v-if="$vuetify.breakpoint.mdAndDown"
      :class="['drawer-button', { 'drawer-button--open': drawerOpen }]"
      @click="onDrawerToggle"
    >
      <open-drawer-icon v-if="!drawerOpen"/>
      <close-drawer-icon v-else/>
    </icon-button>
    <toast-handler/>
  </v-app>
</template>

<script>
import FormSummarySidebar from '@/components/organisms/FormSummarySidebar.vue';
import FormHeader from '@/components/organisms/FormHeader.vue';
import SaveAndShareDialog from '@/components/organisms/SaveAndShareDialog.vue';
import PersonalGuaranteeDialog from '@/components/organisms/PersonalGuaranteeDialog.vue';
import PersonalCardDialog from '@/components/organisms/PersonalCardDialog.vue';
import CarouselNavigation from '@/components/molecules/CarouselNavigation.vue';
import IconButton from '@/components/atoms/IconButton.vue';
import OpenDrawerIcon from '@/assets/icons/mobile-drawer-open.svg';
import CloseDrawerIcon from '@/assets/icons/mobile-drawer-close.svg';
import MobileFormMenu from '@/components/organisms/MobileFormMenu.vue';
import ToastHandler from '@/components/organisms/ToastHandler.vue';
import { mapState, mapGetters } from 'vuex';
import { navigationOffset } from '@/helpers/navigation';
import throttle from 'lodash.throttle';

export default {
  components: {
    FormSummarySidebar,
    FormHeader,
    SaveAndShareDialog,
    PersonalGuaranteeDialog,
    PersonalCardDialog,
    CarouselNavigation,
    IconButton,
    OpenDrawerIcon,
    CloseDrawerIcon,
    MobileFormMenu,
    ToastHandler
  },
  computed: {
    ...mapState({
      drawerOpen: state => state.ui.drawerOpen,
      saverId: state => state.saver.applicationId
    }),
    ...mapGetters({
      currentQuestion: 'navigation/currentQuestion',
      currentQuestions: 'navigation/currentQuestions',
      hasPreviousQuestion: 'navigation/hasPreviousQuestion',
      hasNextQuestion: 'navigation/hasNextQuestion',
      previousQuestion: 'navigation/previousQuestion',
      nextQuestion: 'navigation/nextQuestion'
    })
  },
  mounted() {
    this.throttleOnScroll = throttle(this.handleOnScroll, 300);
    window.addEventListener('scroll', this.throttleOnScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.throttleOnScroll);
  },
  methods: {
    onNavigationClick(htmlId) {
      this.$vuetify.goTo(`#${htmlId}`, {
        offset: navigationOffset(htmlId)
      });
    },
    onPreviousQuestionClick() {
      const { value } = this.previousQuestion;

      this.$vuetify.goTo(`#${value}`, {
        offset: navigationOffset(value)
      });
    },
    onNextQuestionClick() {
      const { value } = this.nextQuestion;

      this.$vuetify.goTo(`#${value}`, {
        offset: navigationOffset(value)
      });
    },
    onDrawerToggle() {
      this.$store.dispatch('ui/toggleDrawer', !this.drawerOpen);
    },
    handleOnScroll() {
      const middleOfPage = window.pageYOffset + window.innerHeight / 2;
      this.$store.dispatch('navigation/changeMiddleOfPage', middleOfPage);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.content {
  /deep/ .v-content__wrap {
    // IE 11
    width: 100%;
  }
}

.left-content {
  width: 100%;

  @include sm {
    padding: rem(0 70px);
  }

  @include md {
    padding: rem(0 70px);
  }
}

.left-container {
  padding-top: 0;
  padding-bottom: 0;

  @include sm {
    padding-top: rem(150px);
  }
}

.drawer-button {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10;
  border-top-left-radius: rem(100px);
  overflow: hidden;
  width: rem(70px);
  height: rem(70px);
  background-color: $orange;
  transition: all 0.3s ease;

  &--open {
    background-color: $white;
  }

  /deep/ svg {
    position: absolute;
    bottom: rem(17px);
    right: rem(15px);
  }
}

.carousel-navigation {
  position: fixed;
  z-index: 5;
  top: rem(20px);
  right: rem(15px);
  transition: all 0.3s ease;

  @include sm {
    top: 50%;
    transform: translate(0, -50%);
  }

  &--drawer-open {
    @include lg {
      right: $form-summary-sidebar-width + rem(35px);
    }
  }
}
</style>
