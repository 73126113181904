<template>
  <span v-if="inline" :class="classes" @click="onClick">
    <slot />
  </span>
  <h1 v-else-if="type === 'h1'" :class="classes" @click="onClick">
    <slot />
  </h1>
  <h2 v-else-if="type === 'h2'" :class="classes" @click="onClick">
    <slot />
  </h2>
  <h3 v-else-if="type === 'h3'" :class="classes" @click="onClick">
    <slot />
  </h3>
  <h4 v-else-if="type === 'h4'" :class="classes" @click="onClick">
    <slot />
  </h4>
  <p v-else :class="classes" @click="onClick">
    <slot />
  </p>
</template>

<script>
/**
 * Typography types taken from Z digital style guide
 */
export default {
  props: {
    align: {
      type: String,
      default: '',
      validator: value => {
        return ['left', 'center', 'right', ''].includes(value);
      }
    },
    inline: {
      type: Boolean,
      default: false
    },
    light: {
      type: Boolean,
      default: false
    },
    medium: {
      type: Boolean,
      default: false
    },
    bold: {
      type: Boolean,
      default: false
    },
    noMargin: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'body',
      validator: value => {
        return [
          'h1',
          'h2',
          'h3',
          'h4',
          'h5',
          'tag',
          'body',
          'small-caption-text',
          'small-note',
          'small-p'
        ].includes(value);
      }
    }
  },
  computed: {
    classes() {
      return [
        'base',
        this.type,
        { bold: this.bold },
        `align-${this.align}`,
        { 'no-margin': this.noMargin },
        { light: this.light },
        { medium: this.medium }
      ];
    }
  },
  methods: {
    onClick() {
      this.$emit('click');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.base {
  max-width: 100%;
}

.h1 {
  font-size: rem(62px);
  font-weight: $weight-black;
  line-height: rem(67px);
  margin-bottom: rem(20px);

  @include md {
    font-size: rem(180px);
    line-height: rem(180px);
    margin-bottom: rem(30px);
  }
}
.h2 {
  font-size: rem(35px);
  font-weight: $weight-black;
  line-height: rem(41px);
  margin-bottom: rem(20px);

  @include md {
    font-size: rem(42px);
    line-height: rem(48px);
    margin-bottom: rem(30px);
  }
}
.h3 {
  font-size: rem(35px);
  font-weight: $weight-light;
  line-height: rem(41px);
  margin-bottom: rem(20px);

  @include md {
    font-size: rem(42px);
    line-height: rem(48px);
    margin-bottom: rem(30px);
  }
}
.h4 {
  font-size: rem(18px);
  font-weight: $weight-black;
  line-height: rem(27px);
  margin-bottom: rem(10px);

  @include md {
    font-size: rem(20px);
    line-height: rem(30px);
    margin-bottom: rem(20px);
  }
}

.h5 {
  font-size: rem(24px);
  font-weight: $weight-medium;
  line-height: rem(28px);
  margin-bottom: rem(10px);

  @include md {
    font-size: rem(28px);
    line-height: rem(32px);
    margin-bottom: rem(10px);
  }
}

.small-p {
  font-size: rem(14px);
  line-height: rem(20px);
  margin-bottom: rem(10px);

  @include md {
    font-size: rem(15px);
  }
}

.body {
  font-size: rem(18px);
  line-height: rem(25px);

  @include md {
    font-size: rem(20px);
    line-height: rem(30px);
  }
}

.small-caption-text {
  font-size: rem(20px);
  font-weight: $weight-light;
  line-height: rem(30px);

  @include md {
    font-size: rem(20px);
    line-height: rem(30px);
  }
}

.small-note {
  font-size: rem(12px);
  line-height: rem(15px);
}

.tag {
  font-size: rem(12px);
  text-transform: uppercase;
  margin-bottom: rem(10px);
  letter-spacing: rem(2px);

  @include sm {
    font-size: rem(15px);
  }
}

.bold {
  font-weight: $weight-bold;
}

.medium {
  font-weight: $weight-medium;
}

.light {
  font-weight: $weight-light;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.no-margin {
  margin: 0;
}
</style>
