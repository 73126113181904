<template>
  <div class="toast-handler">
    <toast
      v-for="(toast, index) in toasts"
      :key="index"
      :value="true"
      :type="toast.type"
      :canDismiss="toast.canDismiss"
      @input="value => onToastInput(value, toast.id)"
      >{{ toast.text }}</toast
    >
  </div>
</template>

<script>
import Toast from '@/components/molecules/Toast.vue';
import { mapState } from 'vuex';

export default {
  components: {
    Toast
  },
  computed: {
    ...mapState({
      toasts: state => state.ui.toasts
    })
  },
  methods: {
    onToastInput(value, id) {
      if (!value) {
        this.$store.dispatch('ui/removeToastById', id);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';
.toast-handler {
  position: fixed;
  bottom: 24px;
  left: 24px;
  z-index: 1000;
}

/deep/ .v-snack {
  position: relative !important;
  margin: 0 0 10px;
  transition: none;
}
</style>
